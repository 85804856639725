import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { useForm } from 'react-hook-form';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import { yupResolver } from '@hookform/resolvers/yup';
import { useValueSet } from 'src/@nicheaim/fhir-react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { convertValueToValueSet } from './common-utils';
import { Box, Button, Dialog, DialogActions, DialogTitle, Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import useLocales from 'src/hooks/useLocales';
import { getPredefinedNote } from '../helpers/common';

type FormValues = {
  topic: string;
  predefinedNote: string;
  description: string;
  medium: string;
};

type Props = {
  open: boolean;
  onCancel: VoidFunction;
  handleCreate: (data: any) => void;
  patient: WrappedPatient | null;
  resource: any;
  typeNote: string;
};

export default function NoteAddForm({
  open,
  patient,
  resource,
  typeNote,
  onCancel,
  handleCreate,
}: Props) {
  const authUser = useAuth().getCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const typeResource = resource?.map((x1) => ({ reference: `${x1?.resourceType}/${x1?.id}` })).
    filter((item) => item?.reference !== 'undefined/undefined');

  const [topicList] = useValueSet('communication-topic', { map: ValueSetWrapper });

  const [communicationCategory] = useValueSet('ph-communication-categories', {
    map: ValueSetWrapper,
  });
  const { i18n } = useLocales();

  const categoryCommunicationCoding = convertValueToValueSet(typeNote, communicationCategory);

  const [communicationMediumList] = useValueSet('v3-ParticipationMode', { map: ValueSetWrapper });

  const [predefinedNotes, setPredefinedNotes] = useState<any>(null);

  const [disabledButton, setDisabledButton] = useState<boolean>(true);

  const getPredefinedNoteCustom = async () => { 
    const data = await getPredefinedNote(patient, 'notes','patient-predefined-notes');
    setPredefinedNotes(data);
  };

  useEffect(() => {
    getPredefinedNoteCustom()
  },[patient])

  const EventSchema = Yup.object().shape({
    topic: Yup.string().required('Topic is required'),
    description: Yup.string().required('Note is required'),
    medium: Yup.string(),
  });

  const defaultValues = {
    topic: '',
    predefinedNote: '',
    description: '',
    medium: '',
  };

  const methods = useForm<FormValues>({ resolver: yupResolver(EventSchema), defaultValues });

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
    watch
  } = methods;

  const { description } = watch();

  useEffect(() => {
    let regex = /[{}]/;
    if(!regex.test(description) && !isEmpty(description)) 
      setDisabledButton(false);
  }, [description])

  const mapFormDataToCommunication = (data: FormValues): any => {
    const mappedData: any = { resourceType: 'Communication' };

    mappedData.status = 'completed';
    mappedData.sent = new Date();
    mappedData.category = [
      {
        coding: [categoryCommunicationCoding],
        text: categoryCommunicationCoding?.display,
      },
    ];
    mappedData.subject = {
      reference: `Patient/${patient?.id}`,
      display: patient?.getFullName(),
    };
    mappedData.sender = {
      ...(!isEmpty(authUser.user_fhir_uri.trim()) && {
        reference: `Practitioner/${getFhirIdFromEntity(authUser.user_fhir_uri)}`,
      }),
      display: authUser.name,
    };

    if (typeResource?.length > 0) {
      mappedData.partOf = typeResource;
    }

    if (data?.description) {
      mappedData.payload = [
        {
          contentString: data.description,
        },
        {
          contentReference: {
            display: data.description,
          },
        },
      ];
    }

    if (data?.topic) {
      mappedData.topic = {
        coding: [
          {
            ...convertValueToValueSet(data?.topic, topicList),
          },
        ],
        text: convertValueToValueSet(data?.topic, topicList)?.display,
      };
    }

    if (data?.medium) {
      const medium = convertValueToValueSet(data?.medium, communicationMediumList);
      if (medium) {
        mappedData.medium = [
          {
            coding: [
              {
                ...medium,
              },
            ],
            ...(medium?.display && { text: medium?.display }),
          },
        ];
      }
    }

    return mappedData;
  };

  const onSubmit = async (data: FormValues) => {
    try {
      const noteData = mapFormDataToCommunication(data);
      await handleCreate(noteData);
      enqueueSnackbar('Communication created successfully!');
      handleClose();
    } catch (error) {
      enqueueSnackbar('Communication was not created', {
        variant: 'error',
        autoHideDuration: 6000,
      });
      handleClose();
    }
  };

  const handleClose = () => {
    reset(defaultValues);
    setDisabledButton(true);
    onCancel();
  };  

 const handleChangePredefinedNote = (event: SelectChangeEvent<unknown>) => {
  setDisabledButton(true);
  const setDescription = 
    predefinedNotes?.find((e) => e.code === event.target.value )?.display ?? "";
  setValue('predefinedNote',String(event.target.value));
  setValue('description',setDescription);
 }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>{i18n('patients.details.notes.titleAddNotes', 'crs')}</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Stack spacing={2} sx={{ p: 2 }}>
              <RHFSelect name="topic" label={i18n('patients.details.notes.topic', 'crs')}>
                <MenuItem disabled />
                {topicList?.asList().map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option?.display}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Stack>

            <Stack spacing={2} sx={{ p: 2 }}>
              <RHFSelect name="medium" label={i18n('patients.details.notes.medium', 'crs')}>
                <MenuItem disabled />
                {communicationMediumList?.asSortedListAll().map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option?.display || option?.code}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Stack>

            {predefinedNotes?.length > 0 && (
              <Stack spacing={2} sx={{ p: 2 }}>
                <RHFSelect 
                  name="predefinedNote" 
                  label={i18n('patients.details.notes.predefinedNote', 'crs')} 
                  onChange={(event) => handleChangePredefinedNote(event)}
                >
                  <MenuItem disabled />
                    {predefinedNotes?.map((option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option?.display}
                      </MenuItem>
                    ))}
                </RHFSelect>
              </Stack>
            )}
            
            <Stack spacing={2} sx={{ p: 2 }}>
              <RHFTextField name="description" label={i18n('patients.details.notes.note', 'crs')} multiline rows={4} />
            </Stack>
          </Grid>
        </Grid>

        <Stack spacing={2} alignItems="center">
          <DialogActions>
            <Box sx={{ flexGrow: 1 }} />

            <Button variant="outlined" color="info" onClick={handleClose}>
              {i18n('cancel')}
            </Button>

            <Button type="submit" color="info" variant="contained" disabled={isSubmitting || disabledButton}>
              {i18n('submit')}

            </Button>
          </DialogActions>
        </Stack>
      </FormProvider>
    </Dialog>
  );
}
