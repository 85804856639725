import Row from "./Row";
import useTable from "src/hooks/useTable";
import { PaginateQuery } from "src/api/pagination/dtos";
import { patientService } from "src/engagement/patient";
import { useCallback, useEffect, useState } from "react";
import CreatePatientEngagement from "../CreatePatientEngagement";
import LoadingTableRow from "src/components/table/LoadingTableRow";
import { TableHeadCustom, TableNoData } from "src/components/table";
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { TABLE_HEAD_PATIENT_ENGAGEMENT_LIST_TABLE } from "src/sections/engagement/common/table-head";
import { Box, Grid, Stack, Table, TableBody, TableContainer, TablePagination, debounce } from "@mui/material";

type Props = {
  patient: WrappedPatient | null;
};

export function ListTable({
  patient
}: Props){

  const { 
    page, 
    rowsPerPage, 
    onChangePage, 
    onChangeRowsPerPage,
  } = useTable({
    defaultRowsPerPage: 5,
    defaultOrderBy: 'id',
    initialIndex: 1,
    defaultCurrentPage: 1,
  });
  
  const [rows, setRows] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const getPaginateQuery = (page: number, rowsPerPage: number) => {
    const paginateQuery: PaginateQuery = { page: page, limit: rowsPerPage };

    paginateQuery.filter = {};

    if (patient) {
      paginateQuery.filter.fhirId = `$in:${patient?.id}`;
    }
    return paginateQuery;
  };

  const getPatientEngagements = async (page: number, rowsPerPage: number) => {
    try {
      setIsLoading(true);
      const patientEngagements = await patientService.getAll(
        getPaginateQuery(page, rowsPerPage)
      );
      setRows(patientEngagements);
      setRefresh(false);
      setIsLoading(false);
    } catch (e) {
      console.log(
        `Something failed while trying to load patient engagements ${e}`
      );
    }
  };

  const getPatientEngagementsList = useCallback(debounce(getPatientEngagements, 600), []);

  useEffect(() => {
    getPatientEngagementsList(page, rowsPerPage);
  }, [page, rowsPerPage, refresh]);

  const handlePageChange = (event: unknown, newPage: number) => {
    onChangePage(event, newPage + 1);
  };

  return(
    <>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            <Stack sx={{ m: 1 }}>
              <Grid container display={'flex'} alignItems={'center'} paddingRight={2}>
                <Grid item xs={2} display={'flex'} alignItems={'flex-end'}>
                  <CreatePatientEngagement 
                    patient={patient} 
                    handlerRefresh={() => setRefresh(true)}
                  />
                </Grid>
              </Grid>
            </Stack>
            <Table>
              <TableHeadCustom 
                headLabel={TABLE_HEAD_PATIENT_ENGAGEMENT_LIST_TABLE} 
              />
              <TableBody>
                {!isLoading ? (
                  rows?.data?.length ? (
                    rows?.data?.map((row: any) => 
                      <Row 
                        key={row.patientEngagementId} 
                        row={row} 
                      />
                    )
                  ) : (
                    <TableNoData isNotFound={true}/>
                  )
                ) : (
                  <LoadingTableRow colSpan={TABLE_HEAD_PATIENT_ENGAGEMENT_LIST_TABLE.length} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows ? rows.meta.totalItems : 0}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handlePageChange}
          onRowsPerPageChange={onChangeRowsPerPage}
          sx={{ borderTop: 0 }}
        />
      </Box>
    </>
  )
}