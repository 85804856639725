import AddNote from "./AddNote";
import api from "src/services/api";
import { mapNotes } from "../common-map";
import { useEffect, useState } from "react";
import { INoteRowInfo } from "src/@types/note";
import { TABLE_HEAD_NOTES } from "../table-head";
import { TableCustom } from "src/components/TableCustom";
import usePermissionsContext from "src/hooks/usePermissionsContext";
import { PatientEngagementPermissions } from "src/sections/crs/types";

type Props = {
  patientEngagementId: string;
}

export default function NotesList({ patientEngagementId }: Props) {

  const { notes } = usePermissionsContext<PatientEngagementPermissions>() ?? {};

  const [notesRowInfoList, setNotesRowInfoList] = useState<INoteRowInfo[]>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (patientEngagementId) {
      getNotes(patientEngagementId);
    }
  }, [patientEngagementId]);

  const getNotes = async (patientEngagementId: string) => {
    const notesList = await api.notes.getGenericNotes(patientEngagementId);

    setNotesRowInfoList(notesList);
  };

  return (
    <>
      <TableCustom
        titleButton={notes?.isAllowedToAdd ? "Add Note" : undefined}
        data={Array.isArray(notesRowInfoList) && mapNotes(notesRowInfoList, ["MemberEngagement"])}
        tableHead={TABLE_HEAD_NOTES}
        handleOpen={()=> setOpen(true)}
      />
      <AddNote
        openDialog={open}
        patientEngagementId={patientEngagementId}
        onCreate={() => {
          getNotes(patientEngagementId);
        }}
        onClose={() => setOpen(false)}
      />
    </>
  );
}
