import { Backdrop, CircularProgress, Grid, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import CustomModal, { CustomModalBasicProps, GridItem, GridSection } from "src/components/CustomModal";
import useLocales from "src/hooks/useLocales";
import { LabelColor } from "./ProviderInformation";
import useObjectState from "src/hooks/useObjectState";
import { StatusChip } from "src/sections/careflow/common";
import uuidv4 from "src/utils/uuidv4";
import { useEffect } from "react";
import { CheckCircle, Pending, PlayCircleFilledRounded } from "@mui/icons-material";
import { WrappedConsent } from "src/@nicheaim/fhir-base/wrappers/Consent";
import { lowerCase } from "lodash";
import { formatDate } from "src/utils/dates";
import moment from "moment";
import { ResourceWithIncludedResources } from "src/sections/crs/types";
import ConsentForm from "src/sections/crs/patient/components/Consent/ConsentForm";
import CancelButton from "src/sections/crs/common/CancelButton";

export interface ConsentOption {
  code: string;
  label: string;
  required: boolean;
};

interface selectedOptionCustomizedButton {
  title: string | null;
  sub: string;
  type: string;
  icon: JSX.Element
  label: string;
  labelColor: LabelColor;
}

interface ConsentSelectedMemberState {
  isSearchMemberOpen: boolean;
  isSearchAttachOpen: boolean;
  selectedOptions: ConsentOption[] | null;
  selectedConsentType: any | null;
  selectedOptionCustomizedButton: selectedOptionCustomizedButton[];
};

interface Props extends CustomModalBasicProps {
  patient: WrappedPatient | null;
  open: boolean;
  isLoading: boolean;
  consentOption: ConsentOption[] | null;
  consents: ResourceWithIncludedResources<WrappedConsent>[];
  handlerSave: () => Promise<any>;
};

export default function ConsentInformation({
  patient, 
  consentOption,
  consents,
  open, 
  isLoading, 
  onClose, 
  handlerSave 
}: Props) {

  const { i18n } = useLocales();
  
  const title = [
  `${i18n('patientEngagement.details.title', 'engagement')}`,
  `${i18n('patientEngagement.details.workflow.step.intake', 'engagement')}`,
  `${i18n('patientEngagement.details.workflow.checklist.consentInformation.title', 'engagement')}`];

  const [
    {
      isSearchMemberOpen,
      isSearchAttachOpen,
      selectedOptions,
      selectedConsentType,
      selectedOptionCustomizedButton
    },
    updateSelectedMember,
  ] = useObjectState<ConsentSelectedMemberState>({
      isSearchMemberOpen: false, 
      isSearchAttachOpen: false,
      selectedOptions: null, 
      selectedConsentType: null,
      selectedOptionCustomizedButton: []
  });

  const [
    {
      consentData
    },
    updateState
  ] = 
  useObjectState<{consentData: ResourceWithIncludedResources<WrappedConsent> | null}>({
    consentData: null
  })

  useEffect(() => {
    if (!open) {
      updateSelectedMember({isSearchMemberOpen: false});
      return;
    };
    updateSelectedMember(
      {
        isSearchMemberOpen, 
        selectedOptions: consentOption, 
        selectedConsentType: null,
        selectedOptionCustomizedButton: [...getSelectOptionCustomButton(consents,consentOption ?? [])]
      });
  }, [open, consentOption, consents]);

  useEffect(() => {
    const consent = consents?.find((e) => 
      e?.resource?.getFirstCategory()?.code === selectedConsentType?.code) ?? null;
    updateState({ consentData: consent });
  }, [selectedConsentType, consents])

  const handleClose = () => {
    if(isSearchMemberOpen){
      updateSelectedMember({ isSearchMemberOpen: false, isSearchAttachOpen: false })
    }else{
      onClose?.({}, 'backdropClick');
    }
  };

  return(
    <CustomModal
      keepMounted
      open={open}
      title={title[2]}
      breadcrumbs={title}
      onCancel={handleClose as Function}
      onClose={handleClose}
      showCancelButton={false}
      showSaveButton={false}
      isLoading={isLoading}
      containerSx={[{ overflow: 'scroll', pb: 0 }, isSearchMemberOpen ? { width: '94vw' } : {}]}
    >
      <>
        <Backdrop
          sx={{ background: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container my={3} justifyContent={'space-between'}>
          <GridSection mt={0}>
            <Typography variant="caption" sx={{ color: 'gray'}}>
              {`${i18n('patientEngagement.details.workflow.checklist.providerInformation.comment', 'engagement')}`}
            </Typography>
          </GridSection>

          <Grid item xs={isSearchMemberOpen ? isSearchAttachOpen ? 1 : 5 : 12}>
            <GridSection>
              <GridItem xs={12} mt={2}>
                <Stack display="flex" spacing={2}>
                  {selectedOptions && selectedOptions.map((option: ConsentOption) => (
                    <CustomizedButton
                      key={uuidv4()}
                      customizedButton={selectedOptionCustomizedButton}
                      type={option.code}
                      isSearchAttachOpen={isSearchAttachOpen}
                      typeSelected={selectedConsentType?.code ?? ''}
                      handler={() => {
                        const optionAsOption = option as ConsentOption;
                        if (optionAsOption) {
                          updateSelectedMember({
                            isSearchMemberOpen: true,
                            selectedConsentType: optionAsOption
                          });
                          return;
                        }
                        
                        updateSelectedMember({ selectedConsentType: null });
                      }}
                    />
                  ))}
                </Stack>
              </GridItem>
            </GridSection>
          </Grid>
          
          {isSearchMemberOpen && (
            <Grid item xs={isSearchAttachOpen ? 10.9 : 6.9}>
              <ConsentForm 
                patient={patient}
                consentData={consentData}
                onSaveChanges={handlerSave}
                open={selectedConsentType}
                onClose={handleClose}
                dontConsentType={true}
                consentTypeCode={selectedConsentType?.code}
                onSelectProperties={(isSearchAttachOpen, consentStatus, provisionPeriodEnd) => {
                  const customizedButton = selectedOptionCustomizedButton?.
                    map((r) => { 
                      if(r?.type === selectedConsentType?.code){
                        return getCustomButton(
                          (consentStatus && provisionPeriodEnd) ?? '', 
                          consentStatus ?? '', 
                          selectedConsentType
                        )}
                      return r;
                    });
                  
                  updateSelectedMember({
                    isSearchAttachOpen,
                    selectedOptionCustomizedButton: customizedButton
                  })
                }}
              />
            </Grid>
          )}

          {!isSearchMemberOpen && (
            <Grid item xs={12} sx={{ mt: '32px', display: 'flex', justifyContent: 'flex-end' }}>
              <CancelButton
                onClick={() => {
                  onClose?.({}, 'backdropClick');
                }}
              />
            </Grid>
          )}
          
        </Grid>
      </>
    </CustomModal>
  )
} 

interface CustomizedButtonProps {
  customizedButton: selectedOptionCustomizedButton[],
  type: string,
  typeSelected: string;
  isSearchAttachOpen: boolean;
  handler: (option: any) => any;
};

function CustomizedButton({ 
  customizedButton,
  type,
  typeSelected,
  isSearchAttachOpen,
  handler
}: CustomizedButtonProps) {
  const getCustomButtonProperties = customizedButton.filter((e) => e.type === type);

  const {icon, title, sub, type: typeCode, label, labelColor } = getCustomButtonProperties[0];

  const isSelected = type === typeSelected;

  return (
    <ListItemButton
      sx={{
        pl: 0,
        ...(isSearchAttachOpen && { flexDirection: 'column', pr: 0 }),
        typography: 'body2',
        color: 'text.secondary',
        border: `1px dashed #e0e0e0`,
        borderRadius: 1,
        bgcolor: 'transparent',
        ...(isSelected && { boxShadow: 12 }),
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: 7
        },
      }}
      disableTouchRipple
      alignItems="center"
      onClick={handler}
    >
      <ListItemIcon sx={{ mx: 1 }}>{icon}</ListItemIcon>
      <ListItemText 
        disableTypography 
        primary={isSearchAttachOpen ? typeCode : title}
        secondary={!isSearchAttachOpen && (
          <Typography 
            variant="body2"
            sx={{ display: 'inline', ...(title && { pl: 1 })}}
          >
            {sub}
          </Typography>
        )}
      />
      {!isSearchAttachOpen && <StatusChip label={label} color={labelColor} size="small" />}
    </ListItemButton>
  );
};

const getCustomButton =  (dateEnd: string, status: string, consentOption: ConsentOption) => {
  switch(lowerCase(status)){
    case 'active': 
      return  {
        title: consentOption.label,
        sub: dateEnd ? `Valid: ${formatDate(moment(dateEnd) ?? null)}` : '',
        type: consentOption.code,
        icon: <CheckCircle htmlColor="#00ab55" color='primary'/>,
        label: 'Completed',
        labelColor: 'success' as LabelColor
      }
    case 'proposed': 
      return {
        title: consentOption.label,
        sub: dateEnd ? `Valid: ${formatDate(moment(dateEnd) ?? null)}` : '',
        type: consentOption.code,
        icon: <Pending htmlColor="#00b8d9"/>,
        label: 'In Progress',
        labelColor: 'progress' as LabelColor
      }
    case 'draft': 
      return {
        title: consentOption.label,
        sub: dateEnd ? `Valid: ${formatDate(moment(dateEnd) ?? null)}` : '',
        type: consentOption.code,
        icon: <Pending htmlColor="#00b8d9"/>,
        label: 'In Progress',
        labelColor: 'progress' as LabelColor
      }
    default:
      return {
        title: consentOption.label,
        sub: '',
        type: consentOption.code,
        icon: <PlayCircleFilledRounded />,
        label: 'Not Started',
        labelColor: 'default' as LabelColor
      }
  }
};

export const getSelectOptionCustomButton = (
  resource: ResourceWithIncludedResources<WrappedConsent>[], 
  providerOption: ConsentOption[]
) => {
  const customButtonArray = providerOption?.map((option: ConsentOption) => {
    const resourceByRole = resource?.find((item: ResourceWithIncludedResources<WrappedConsent>) => 
      item?.resource?.category?.[0]?.coding?.[0]?.code === option?.code) ?? null;
    return getCustomButton(resourceByRole?.resource?.provision?.period?.end ?? '',
      resourceByRole?.resource?.status ?? '', 
      option
    );
  }).flat();
  return customButtonArray;
};