import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import DataGridWithFilters from '../../case/components/DataGridWithFilters/DataGridWithFilters';
import { GridColDef } from '@mui/x-data-grid';
import useTranslations from 'src/hooks/useTranslations';
import { PermissionsBase, PermissionsProvider } from 'src/contexts/PermissionsContext';
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { Link, LinkOff, LocalHospital, Mail, Phone } from '@mui/icons-material';
import useClientGrid from 'src/hooks/useClientGrid';
import { defaultRowsPerPageOptions } from '../../constants';
import {
  Bundle,
  Organization,
  Practitioner,
  PractitionerRole,
  Reference,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import CellRow from '../../common/CellRow';
import StethoscopeIcon from '../../../../assets/icons/stethoscope.svg';
import { Box, DialogContentText, SxProps } from '@mui/material';
import { getReferenceId, getReferenceType } from 'src/utils/fhir';
import { fhirClient } from 'src/App';
import {
  PractitionerRoleWrapper,
  WrappedPractitionerRole,
} from 'src/@nicheaim/fhir-base/wrappers/PractitionerRole';
import {
  OrganizationWrapper,
  WrappedOrganization,
} from 'src/@nicheaim/fhir-base/wrappers/Organization';
import {
  PractitionerWrapper,
  WrappedPractitioner,
} from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import { formatUSAddress } from 'src/utils/address';
import ActionButton from 'src/components/ActionButton';
import { NestedMenuItem } from 'mui-nested-menu';
import { usePatients } from 'src/@nicheaim/fhir-react';
import ConfirmDialog from '../ConfirmDialog';
import useNotiMessage from 'src/hooks/useNotiMessage';
import AssignGeneralPractitionerModal from '../AssignGeneralPractitionerModal';
import { GeneralPractitionerGrid } from '../../types';

export interface GeneralPractitionerListProps {
  patient: WrappedPatient | null;
}

export interface GeneralPractitionerPermissions extends PermissionsBase {
  isAllowedToAdd: boolean;
  isAllowedToDelete: boolean;
}

const iconSize: CSSProperties = { height: 15, width: 15 };
const iconColor: CSSProperties['color'] = '#919eab';

const GeneralPractitionerList = ({ patient }: GeneralPractitionerListProps) => {
  const translate = useTranslations(`patients.generalPractitioners`);
  const [generalPractitioners, setGeneralPractitioners] = useState<GeneralPractitionerGrid[]>([]);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
  const [selectedGeneralPractitioner, setSelectedGeneralPractitioner] = useState<Reference | null>(
    null
  );
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [_, { update: updatePatient }] = usePatients({
    autofetch: false,
  });

  const { sortModel, handleSortModelChange } = useClientGrid({
    initialRowsPerPage: 10,
    defaultSort: [{ field: 'date', sort: 'desc' }],
  });

  const [isLoading, setIsLoading] = useState(true);

  const { showErrorMessage, showSuccessMessage } = useNotiMessage();

  const permissions: GeneralPractitionerPermissions = useMemo(
    () => ({
      isAllowedToAdd: checkAclValidation({ acls: [crsAcls.CRS.PATIENT.GENERAL_PRACTITIONER.ADD] }),
      isAllowedToDelete: checkAclValidation({
        acls: [crsAcls.CRS.PATIENT.GENERAL_PRACTITIONER.DELETE],
      }),
    }),
    []
  );

  const handleCloseConfirmDialog = () => {
    setIsConfirmDialogVisible(false);
  };

  const handleRemoveGeneralPractitioner = async () => {
    if (!patient) return;
    try {
      setIsRemoving(true);
      await updatePatient({
        ...patient,
        generalPractitioner: [
          ...(patient?.generalPractitioner ?? []).filter(
            (reference) => reference !== selectedGeneralPractitioner
          ),
        ],
      });

      showSuccessMessage(translate('successMessage.unlinkGeneralPractitioner'));
      handleCloseConfirmDialog();
    } catch (error) {
      showErrorMessage(translate('errorMessage.unlinkGeneralPractitioner'));
    }
    setIsRemoving(false);
  };

  const getGeneralPractitioners = useCallback(async () => {
    try {
      setIsLoading(true);
      const patientGeneralPractitioners = patient?.generalPractitioner ?? [];
      let [organizationIds, practitionerIds, practitionerRoleIds] =
        patientGeneralPractitioners.reduce<[string[], string[], string[]]>(
          ([organizations, practitioners, practitionerRoles], generalPractitioner) => {
            const resourceId = getReferenceId(generalPractitioner.reference);
            const resourceType = getReferenceType(generalPractitioner.reference);
            if (!resourceId) return [organizations, practitioners, practitionerRoles];
            switch (resourceType) {
              case 'Practitioner':
                practitioners.push(resourceId);
                break;
              case 'Organization':
                organizations.push(resourceId);
                break;
              case 'PractitionerRole':
                practitionerRoles.push(resourceId);
            }
            return [organizations, practitioners, practitionerRoles];
          },
          [[], [], []]
        ) ?? [];

      const allPractitionerRoleIds = [...new Set(practitionerRoleIds)];
      const prResponse = allPractitionerRoleIds.length
        ? await fhirClient.get<Bundle<PractitionerRole>>(
            `PractitionerRole?_id=${allPractitionerRoleIds.join()}`
          )
        : null;

      let practitionerRoles =
        prResponse?.entry?.reduce?.<WrappedPractitionerRole[]>((resources, { resource }) => {
          if (!resource) return resources;
          return [...resources, PractitionerRoleWrapper(resource)];
        }, []) ?? [];

      const [organizationReferenceIds, practitionerReferenceIds] = practitionerRoles.reduce<
        [string[], string[]]
      >(
        ([organizationIds, practitionerIds], practitionerRole) => {
          const organizationId = getReferenceId(practitionerRole?.organization?.reference);
          const practitionerId = getReferenceId(practitionerRole?.practitioner?.reference);
          if (organizationId) organizationIds.push(organizationId);
          if (practitionerId) practitionerIds.push(practitionerId);
          return [organizationIds, practitionerIds];
        },
        [[], []]
      );

      const allOrganizationIds = [...new Set([...organizationReferenceIds, ...organizationIds])];

      const organizationResponse = allOrganizationIds.length
        ? await fhirClient.get<Bundle<Organization>>(
            `Organization?_id=${allOrganizationIds.join()}`
          )
        : null;

      const organizations =
        organizationResponse?.entry?.reduce?.<WrappedOrganization[]>((resources, { resource }) => {
          if (!resource) return resources;
          return [...resources, OrganizationWrapper(resource)];
        }, []) ?? [];

      const allPractitionerIds = [...new Set([...practitionerReferenceIds, ...practitionerIds])];

      const practitioneresponse = allPractitionerIds.length
        ? await fhirClient.get<Bundle<Practitioner>>(
            `Practitioner?_id=${allPractitionerIds.join()}`
          )
        : null;

      const practitioners =
        practitioneresponse?.entry?.reduce?.<WrappedPractitioner[]>((resources, { resource }) => {
          if (!resource) return resources;
          return [...resources, PractitionerWrapper(resource)];
        }, []) ?? [];

      const allResources = [...practitioners, ...organizations, ...practitionerRoles];

      const generalPractitioners: GeneralPractitionerGrid[] = patientGeneralPractitioners
        .map((reference) => {
          const resource = allResources.find(
            ({ id }) => id === getReferenceId(reference?.reference)
          );
          if (!resource) return null;
          if (resource?.resourceType === 'Organization') {
            const organization = resource as WrappedOrganization;
            return {
              reference,
              id: organization.id as string,
              organization: {
                id: organization.id,
                fullName: organization?.name,
              },
              phone: organization.getPhones()?.[0],
              email: organization.getEmails()?.[0],
              address: formatUSAddress(organization.getPrimaryAddress()),
            };
          }
          if (resource?.resourceType === 'Practitioner') {
            const practitioner = resource as WrappedPractitioner;
            return {
              reference,
              id: practitioner.id as string,
              npi: practitioner.getNPI(),
              practitioner: {
                id: practitioner.id,
                fullName: practitioner.getFullName(),
              },
              phone: practitioner.getPhones()?.[0]?.value,
              email: practitioner.getEmails()?.[0]?.value,
              address: formatUSAddress(practitioner.getPrimaryAddress()),
            };
          }
          const practitionerRole = resource as WrappedPractitionerRole;
          const practitioner = practitioners.find(
            ({ id }) => id === getReferenceId(practitionerRole?.practitioner?.reference)
          );
          const organization = organizations.find(
            ({ id }) => id === getReferenceId(practitionerRole?.organization?.reference)
          );
          return {
            reference,
            id: practitionerRole.id as string,
            npi: practitioner?.getNPI?.(),
            role: practitionerRole?.code?.[0],
            practitioner: {
              id: practitioner?.id,
              fullName: practitioner?.getFullName?.(),
            },
            organization: {
              id: organization?.id,
              fullName: organization?.name,
            },
            phone: practitioner?.getPhones?.()?.[0]?.value,
            email: practitioner?.getEmails?.()?.[0]?.value,
            address: formatUSAddress(practitioner?.getPrimaryAddress?.()),
          };
        })
        .filter((generalPractitioner) => !!generalPractitioner) as GeneralPractitionerGrid[];

      setGeneralPractitioners(generalPractitioners);
    } catch (error) {}
    setIsLoading(false);
  }, [patient?.generalPractitioner]);

  useEffect(() => {
    getGeneralPractitioners();
  }, [getGeneralPractitioners]);

  const gridColumns: GridColDef<GeneralPractitionerGrid>[] = [
    {
      flex: 1,
      sortable: false,
      field: 'npi',
      headerName: translate('npi'),
      renderCell: (params) => {
        const { npi } = params.row;

        return <CellRow shouldTruncateText={false} title={npi} />;
      },
    },
    {
      flex: 1,
      sortable: false,
      field: 'practitionerAndOrg',
      headerName: translate('practitionerAndOrg'),
      renderCell: (params) => {
        const { practitioner, organization } = params.row;
        return (
          <Box>
            {!!practitioner?.fullName && (
              <CellRow
                tooltipTitle={translate('practitioner')}
                Icon={<StethoscopeIcon style={{ ...iconSize, color: iconColor }} />}
                shouldTruncateText={false}
                title={practitioner?.fullName}
              />
            )}
            {!!organization?.fullName && (
              <CellRow
                tooltipTitle={translate('organization')}
                Icon={<LocalHospital sx={iconSize} htmlColor={iconColor} />}
                shouldTruncateText={false}
                title={organization?.fullName}
              />
            )}
          </Box>
        );
      },
    },
    {
      flex: 1,
      sortable: false,
      field: 'role',
      headerName: translate('role'),
      renderCell: (params) => {
        const { role } = params.row;
        return (
          <CellRow shouldTruncateText={false} title={role?.coding?.[0]?.display ?? role?.text} />
        );
      },
    },
    {
      flex: 1,
      sortable: false,
      field: 'contactDetails',
      headerName: translate('contactDetails'),
      renderCell: (params) => {
        const { phone, email } = params.row;
        return (
          <Box>
            <CellRow
              tooltipTitle={translate('phone')}
              shouldTruncateText={false}
              title={phone}
              Icon={<Phone sx={{ ...iconSize }} htmlColor={iconColor} />}
            />
            <CellRow
              tooltipTitle={translate('email')}
              shouldTruncateText={false}
              title={email}
              Icon={<Mail sx={{ ...iconSize }} htmlColor={iconColor} />}
            />
          </Box>
        );
      },
    },
    {
      flex: 1,
      sortable: false,
      field: 'address',
      headerName: translate('address'),
      renderCell: (params) => {
        const { address } = params.row;
        return (
          <CellRow
            shouldTruncateText={false}
            titleSx={{
              whiteSpace: 'pre-line',
              lineHeight: 1.5,
            }}
            title={address}
          />
        );
      },
    },
    {
      flex: 0.3,
      field: 'action',
      sortable: false,
      align: 'center',
      headerName: ' ',
      renderCell: (params) => {
        const { reference } = params.row;
        return (
          <ActionButton
            renderChildren={(isMenuOpen, setIsMenuOpen) => (
              <>
                {!!permissions.isAllowedToDelete && (
                  <NestedMenuItem
                    sx={nestedMenuItemStyles}
                    onClick={() => {
                      setIsMenuOpen(false);
                      setSelectedGeneralPractitioner(reference ?? null);
                      setIsConfirmDialogVisible(true);
                    }}
                    leftIcon={<LinkOff />}
                    rightIcon={null}
                    label={translate('unlink')}
                    parentMenuOpen={isMenuOpen}
                  />
                )}
              </>
            )}
          />
        );
      },
    },
  ];

  return (
    <>
      <PermissionsProvider permissions={permissions}>
        <DataGridWithFilters
          getRowSpacing={() => ({ bottom: 1, top: 2 })}
          addButtonTitle={translate('existingPractitioner')}
          addButtonIcon={<Link />}
          title={''}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          onAddButtonClick={() => {
            setIsAssignModalOpen(true);
          }}
          density="standard"
          loading={isLoading}
          rowsPerPageOptions={defaultRowsPerPageOptions}
          getRowHeight={() => 'auto'}
          rows={generalPractitioners}
          columns={gridColumns}
          showPagination={true}
          autoHeight={true}
          isNestedGrid={false}
        />
      </PermissionsProvider>
      <ConfirmDialog
        open={isConfirmDialogVisible}
        onDialogClose={() => {
          handleCloseConfirmDialog();
        }}
        onConfirm={() => {
          handleRemoveGeneralPractitioner();
        }}
        dialogTitle={translate('confirmGeneralPractitionerUnlinkTitle')}
        ConfirmButtonIcon={<LinkOff />}
        confirmButtonTitle={translate('confirmGeneralPractitionerUnlinkButton')}
        isLoading={isRemoving}
      >
        <DialogContentText sx={{ marginTop: 2 }}>
          {translate('confirmGeneralPractitionerUnlinkMessage')}
        </DialogContentText>
      </ConfirmDialog>
      <AssignGeneralPractitionerModal
        open={isAssignModalOpen}
        onClose={() => {
          setIsAssignModalOpen(false);
        }}
        patient={patient}
      />
    </>
  );
};

const nestedMenuItemStyles: SxProps = {
  paddingX: 3,
};
export default GeneralPractitionerList;
