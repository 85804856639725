import { 
  Backdrop,
  Button, 
  Card, 
  CircularProgress, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  Divider, 
  Drawer, 
  FormHelperText, 
  Grid, 
  IconButton, 
  List, 
  ListItem, 
  MenuItem, 
  Stack, 
  TextField, 
  Typography 
} from "@mui/material";
import * as Yup from 'yup';
import { isEmpty } from "lodash";
import InputMask from 'react-input-mask';
import { capitalCase } from "change-case";
import { phoneFormat } from "src/utils/fhir";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Add, Check, Edit } from "@mui/icons-material";
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { FormProvider, RHFSelect, RHFTextField } from "src/components/hook-form";
import { WrappedRelatedPerson } from "src/@nicheaim/fhir-base/wrappers/RelatedPerson";
import AddAddress from "src/sections/crs/referral/components/workflow-step/AddressChecklistItem/AddAddress";

type Props = {
  relatedPerson: WrappedRelatedPerson | null;
  patient: WrappedPatient | null;
  isOpen: boolean;
  handlerIsOpen: Function;
  handleRelatedPerson: (data: any) => Promise<any>;
}

export default function ConfirmGuarantorInfo({
  relatedPerson,
  patient,
  isOpen,
  handlerIsOpen,
  handleRelatedPerson,
}: Props) {

  const [editMode, setEditMode] = useState(false);
  const [guarantorLoaded, setGuarantorLoaded] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [address, setAddress] = useState({});
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const MINIMUM_AGE_TO_SKIP = 18;

  function getMemberAge(dob: string): any {
    const convertDOB = new Date(dob);
    const dateNow = new Date();
    
    const age = dateNow.getFullYear() - convertDOB.getFullYear();
    
    return age;
  }

  const schema = Yup.object().shape({
    given: Yup.string().required("First Name is required"),
    middle_name: Yup.string(),
    family: Yup.string().required("Last Name is required"),
    gender: Yup.string().required("Gender is required"),
    emailType: Yup.string()
    .nullable()
    .transform((curr, orig) => orig === false ? undefined : curr)
    .when('emailAddress', (value, schema) => {
      if (value) return schema.required('Email Type is required');
    }),
    emailAddress: Yup.string()
      .email("Email must be a valid email address"),
    phoneType: Yup.string()
    .nullable()
    .transform((curr, orig) => orig === false ? undefined : curr)
    .when('phoneNumber', (value, schema) => {
      if (value) return schema.required('Phone Type is required');
    }),
    address_use: Yup.string().required("Address use is required"),
    address_line: Yup.string().required("Street is required"),
    address_line_2: Yup.string(),
    address_city: Yup.string().required("City is required"),
    address_state: Yup.string().required("State is required"),
    address_postal: Yup.string().required("Zip Code is required"),
  });

  const defaultValues = useMemo(
    () =>
    ({
      id: relatedPerson?.id || '',
      given: relatedPerson?.name?.[0]?.given?.[0] || '',
      middle_name: relatedPerson?.name?.[0]?.given?.[1] || '',
      family: relatedPerson?.name?.[0]?.family || '',
      gender: relatedPerson?.gender || '',
      emailType: relatedPerson?.getPrimaryEmail()?.use || "",
      emailAddress: relatedPerson?.getPrimaryEmail()?.value || "",
      phoneType: relatedPerson?.getPrimaryPhone()?.use || "",
      phoneNumber: relatedPerson?.getPrimaryPhone()?.value || "",
      language: {
        language: relatedPerson?.communication?.[0]?.language?.coding?.[0]?.display
          || relatedPerson?.communication?.[0]?.language?.text || '',
        isDefault:relatedPerson?.communication?.[0]?.preferred || false,
      },
      address_use: relatedPerson?.getPrimaryAddress()?.use || "",
      address_line: relatedPerson?.getPrimaryAddress()?.line?.[0] || "",
      address_line_2: relatedPerson?.getPrimaryAddress()?.line?.[1] || "",
      address_city: relatedPerson?.getPrimaryAddress()?.city || "",
      address_state: relatedPerson?.getPrimaryAddress()?.state || "",
      address_postal: relatedPerson?.getPrimaryAddress()?.postalCode || "",
      address_district: relatedPerson?.getPrimaryAddress()?.district || '',
    }),[relatedPerson]
  );

  const methods = useForm({ resolver: yupResolver(schema), defaultValues });
  
  const {
    control,
    watch,
    reset,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors }
  } = methods;

  const values = watch();

  useEffect(() => {
    setGuarantorLoaded(false);
    reset(defaultValues);
    setEditMode(false);
    setGuarantorLoaded(true);
  }, [isOpen]);

  const saveChanges = async (data: any) => {
    setValue('address_line', data.line[0]);
    setValue('address_line_2', data.line[1]);
    setValue('address_city', data.city);
    setValue('address_district', data.district);
    setValue('address_postal', data.postalCode);
    setValue('address_state', data.state);
    setValue('address_use', data.use);
    clearErrors(['address_line','address_city','address_postal','address_state'])
    setAddress(data);
    setOpenAddAddress(false);
  };

  const handlerSkip = ()  => {
    setOpenBackdrop(true);
    handleRelatedPerson({skip: true});
    reset(defaultValues);
    setOpenBackdrop(false);
    handlerIsOpen(false);
  };

  const onSubmit = async (values: any) => {

    setOpenBackdrop(true);

    const email: any = {
      rank: 1,
      use: values?.emailType,
      system: 'email',
      value: values?.emailAddress,
    };

    const phone: any = {
      rank: 1,
      use: values?.phoneType,
      system: 'phone',
      value: values?.phoneNumber,
    };

    const gender: any = values?.gender;

    const getAddress = !isEmpty(address) ? [address] : relatedPerson?.address;
    
    const payload = {
      // Add id to object only of it's in data(updating an existing related person)
      ...(values?.id && { id: values?.id }),
      resourceType: 'RelatedPerson',
      name: [
        {
          // add family property to name only if family exists and is not null
          ...(values?.family && {
            family: values?.family,
          }),
          // add given property to name only if given exists and is not null
          ...(values?.given && {
            given: [values?.given, ...(values?.middle_name ? [values?.middle_name] : [])],
          }),
        },
      ],
      // add telecom only if phone or email exist
      ...((phone?.value || email?.value) && {
        telecom: [...(phone?.value ? [phone] : []), ...(email?.value ? [email] : [])],
      }),
      ...(getAddress && { address: getAddress }),
      ...(gender && { gender: gender }),
      patient: { reference: `${patient?.resourceType}/${patient?.id}`}
    };

    handleRelatedPerson(payload);

    reset(defaultValues);
    setOpenBackdrop(false);
    handlerIsOpen(false);
  };

  const handleCloseAlert = () => setOpenAlert(false);

  const handleAgreeAlert = () => {
    setOpenAlert(false);
    handlerIsOpen(false);
  };

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={() => {
        handlerIsOpen(false);
      }}
    >
      {guarantorLoaded ? (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <List component="nav">
            <ListItem>
              <Grid container justifyContent="space-between">
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    type="submit"
                  >
                    Confirm Parent/Caregiver Info
                  </Button>
                  <Button
                    onClick={() => setOpenAlert(true)}
                  >
                    Cancel
                  </Button>
                </Stack>
                <Grid>
                  {getMemberAge(patient?.birthDate || '') >= MINIMUM_AGE_TO_SKIP && (
                    <Button variant="contained" color="error" onClick={handlerSkip}>Skip</Button>
                  )}
                  <IconButton onClick={() => setEditMode((prev) => !prev)}>
                    {editMode ? <Check /> : <Edit />}
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
            <Divider />
            <ListItem>
              <Grid
                container
                direction="column"
                marginTop={2}
                width={800}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  minHeight={60}
                >
                  <Grid container item xs={5}>
                    <Typography>Name</Typography>
                  </Grid>

                  <Grid container item xs={7}>
                    {editMode ? (
                      <Stack
                        direction="column"
                        spacing={1}
                        sx={{ width: "100%" }}
                      >
                        <Grid xs={12}>
                          <RHFTextField name="given" label="First Name" />
                        </Grid>
                        <Grid xs={12}>
                          <RHFTextField name="middle_name" label="Middle Name" />
                        </Grid>
                        <Grid xs={12}>
                          <RHFTextField name="family" label="Last Name" />
                        </Grid>
                      </Stack>
                    ) : (
                      <Stack direction="column">
                        <Typography>
                          <b>{`${values.given} ${values.middle_name} ${values.family}`}</b>
                        </Typography>
                        {!!errors.given && 
                            <FormHelperText error> {errors.given?.message}</FormHelperText>}

                        {!!errors.family && 
                            <FormHelperText error> {errors.family?.message}</FormHelperText>}
                        
                      </Stack>
                    )}
                  </Grid>
                </Grid>
                
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  minHeight={60}
                  sx={{ mt: 1 }}
                >
                  <Grid container item xs={5}>
                    <Typography>Gender</Typography>
                  </Grid>

                  <Grid container item xs={7}>
                    {editMode ? (
                      <RHFSelect name="gender" label="Gender" fullWidth={true}>
                        <MenuItem disabled />
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                        <MenuItem value="unknown">Unknown</MenuItem>
                      </RHFSelect>
                    ) : (
                      <Stack direction="column">
                        <Typography>
                          <b>{capitalCase(values.gender)}</b>
                        </Typography>

                        {!!errors.gender && 
                            <FormHelperText error> {errors.gender?.message}</FormHelperText>}
                      </Stack>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  minHeight={60}
                  sx={{ mt: 1 }}
                >
                  <Grid container item xs={5}>
                    <Typography>Email</Typography>
                  </Grid>

                  <Grid container item xs={7}>
                    {editMode ? (
                      <>
                        <Grid xs={3}>
                          <RHFSelect name="emailType" label="Email Type" fullWidth={true}>
                            <MenuItem disabled />
                            <MenuItem value="home">Home</MenuItem>
                            <MenuItem value="work">Work</MenuItem>
                          </RHFSelect>
                        </Grid>
                        <Grid xs={9}>
                          <RHFTextField name="emailAddress" label="Email" fullWidth />
                        </Grid>
                      </>
                    ) : (
                      <Stack direction="column">
                        <Typography>
                          <b>{`${values.emailAddress} (${capitalCase(values.emailType)})`}</b>
                        </Typography>
                      </Stack>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  minHeight={60}
                  sx={{ mt: 1 }}
                >
                  <Grid container item xs={5}>
                    <Typography>Phone</Typography>
                  </Grid>

                  <Grid container item xs={7}>
                    {editMode ? (
                      <>
                        <Grid xs={3}>
                          <RHFSelect name="phoneType" label="Phone Type" fullWidth={true}>
                            <MenuItem disabled />
                            <MenuItem value="home">Home</MenuItem>
                            <MenuItem value="work">Work</MenuItem>
                            <MenuItem value="mobile">Mobile</MenuItem>
                          </RHFSelect>
                        </Grid>
                        <Grid xs={9}>
                          <Controller
                            name="phoneNumber"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <InputMask mask="999-999-9999" value={value} onChange={onChange}>
                                {(inputProps: any) => (
                                  <TextField
                                    label="Phone Number"
                                    {...inputProps}
                                    error={!!error?.message}
                                    helperText={error?.message}
                                  />
                                )}
                              </InputMask>
                            )}
                          />
                        </Grid>
                      </>
                    ) : (
                      <Stack direction="column">
                        <Typography>
                          <b>{`${phoneFormat(
                            values.phoneNumber
                          )} (${capitalCase(values.phoneType)})`}</b>
                        </Typography>
                      </Stack>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  minHeight={60}
                >
                  <Grid container item xs={5}>
                    <Typography>Address</Typography>
                  </Grid>

                  <Grid container item xs={7}>
                    {editMode ? (
                       <>
                          <Grid xs={12} marginTop={1} display="flex" justifyContent="flex-end">
                            <Typography
                              style={{ display: 'flex', justifyContent: 'space-between' }}
                              variant="button"
                              sx={{ textTransform: 'uppercase' }}
                            >
                              <Button
                                color="primary"
                                startIcon={<Add />}
                                onClick={() => setOpenAddAddress(true)}
                              >
                                Add
                              </Button>
                            </Typography>
                          </Grid>
                          <Grid xs={6} marginTop={1}>
                            <RHFTextField name="address_line" label="Street" disabled/>
                          </Grid>
                          <Grid xs={6} marginTop={1}>
                            <RHFTextField name="address_line_2" label="Street 2" disabled/>
                          </Grid>
                          <Grid xs={6} marginTop={1}>
                            <RHFTextField name="address_city" label="City" disabled/>
                          </Grid>
                          <Grid xs={6} marginTop={1}>
                            <RHFTextField name="address_state" label="State" disabled/>
                          </Grid>
                          <Grid xs={6} marginTop={1}>
                            <RHFTextField name="address_district" label="County" disabled/>
                          </Grid>
                          <Grid xs={6} marginTop={1}>
                            <RHFTextField name="address_postal" label="Zip Code" disabled/>
                          </Grid>
                        </>
                    ) : (
                      <Grid xs={9}>
                        <Typography>
                          <b>{
                            `${values.address_line || ''}, ${values.address_line_2 || ''} ${values.address_city || ''}, 
                            ${values.address_state || ''}, ${values.address_district || ''},  ${values.address_postal || ''}`
                          }</b>
                        </Typography>
                        <Stack>
                          {!!errors.address_line && 
                            <FormHelperText error> {errors.address_line?.message}</FormHelperText>}

                          {!!errors.address_city && 
                            <FormHelperText error> {errors.address_city?.message}</FormHelperText>}
                          
                          {!!errors.address_state && 
                            <FormHelperText error> {errors.address_state?.message}</FormHelperText>}
                          
                          {!!errors.address_postal && 
                            <FormHelperText error> {errors.address_postal?.message}</FormHelperText>}
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </FormProvider>
      ) : (
        <Stack width={500} alignItems="center" marginTop={50}>
          <CircularProgress />
        </Stack>
      )}

      <Dialog
        open={openAlert}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Parent/Caregiver Info"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          If you cancel your previous changes will be lost, do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlert} variant="contained" color="error" sx={{ marginRight: 1 }}>Disagree</Button>
          <Button onClick={handleAgreeAlert} autoFocus variant="contained" color="success">
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAddAddress} fullWidth maxWidth="md">
        <DialogTitle> Add Address</DialogTitle>
        <Card sx={{ p: 2, mx: 2 }}>
          <AddAddress 
            externalAddress={{
              line: relatedPerson?.getPrimaryAddress()?.line?.[0] || '',
              city: relatedPerson?.getPrimaryAddress()?.city || '',
              district: relatedPerson?.getPrimaryAddress()?.city || '',
              state: relatedPerson?.getPrimaryAddress()?.state || '',
              postalCode: relatedPerson?.getPrimaryAddress()?.postalCode || '',
              country: relatedPerson?.getPrimaryAddress()?.country || '', 
            }}
            handleClose={() => setOpenAddAddress(false)} 
            handleSave={saveChanges} 
          />
        </Card>
      </Dialog>
    </Drawer>
  );
}