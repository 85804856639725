import * as Yup from 'yup';
import api from 'src/services/api';
import { useForm } from 'react-hook-form';
import { NoteDto } from 'src/@types/note';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { Button, CardContent, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';

type Props = {
  openDialog: boolean;
  patientEngagementId: string;
  onCreate: () => void;
  onClose: () => void;
};

export default function AddNote({
  openDialog,
  patientEngagementId,
  onCreate,
  onClose
}: Props) {

  const schema = Yup.object().shape({
    text: Yup.string().required("Text is required"),
  });

  const defaultValues = {
    text: undefined
  }

  const methods = useForm({ resolver: yupResolver(schema), defaultValues});

  const {
    reset,
    handleSubmit,
  } = methods;

  const handlerClose = () => {
    onClose();
    reset(defaultValues);
  };

  const onSubmit = async (values: any) => {
    try {
      const newNote: NoteDto = {
        referenceId: Number(patientEngagementId),
        text: values.text,
        type: "MemberEngagement",
      };

      const response = await api.notes.createNote(newNote);
      onCreate();
      
    } catch (error) {
      console.log(error);
    } finally {
      handlerClose();
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handlerClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title"> Add Note</DialogTitle>
      <DialogContent>
        <CardContent>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" spacing={3}>
              <Grid item md={12}>
                <RHFTextField name="text" label="Text" multiline rows={2} fullWidth={true}/>
              </Grid>

              <Grid item md={12} container direction="row">
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </FormProvider>
        </CardContent>
      </DialogContent>
    </Dialog>
  );
}
