import { Box, Button, Divider, Typography } from '@mui/material';
import {
  PractitionerWrapper,
  WrappedPractitioner,
} from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import { usePractitioner } from 'src/@nicheaim/fhir-react';
import useLocales from 'src/hooks/useLocales';
import VerifiedDecorator from './VerifiedDecorator';
import { formatDate, getAge, getTimeDiff } from 'src/utils/dates';
import moment from 'moment';
import { Option } from 'src/@types/crs/case';
import PreviewDetailDrawer, {
  InfoRow,
  InfoRowValueList,
  PreviewDetailDrawerProps,
  SectionInfo,
} from '../common/PreviewDetailDrawer';
import { orderTelecomByRank } from 'src/utils/fhir';
import { formatUSAddress } from 'src/utils/address';
import { px } from 'src/components/CustomDrawer';
import { CRS_PATH } from 'src/routes/paths';
import PractitionerProfileImage from './PractitionerDetails/PractitionerProfileImage';
import { PH_META_TAG_CREATED_ON_CODE } from 'src/config';
import { getFormattedPersonDOB } from 'src/sections/YoCare/utils/personHelpers';

export interface PractitionerDrawerProps extends Omit<PreviewDetailDrawerProps, 'title'> {
  practitioner?: WrappedPractitioner | null;
  practitionerId?: string;
}

const commonVerticalSpacing = 2.3;

const PractitionerDrawer = ({
  practitioner,
  practitionerId,
  onClose,
  containerSx,
  ...drawerProps
}: PractitionerDrawerProps) => {
  const { i18n } = useLocales();
  const [practitionerResource, { isFetching }] = usePractitioner(practitionerId, {
    map: PractitionerWrapper,
    autofetch: !!practitionerId,
  });

  const practitionerFhir = (practitioner ?? practitionerResource) as WrappedPractitioner;

  const identifiers: Option<string>[] = [
    ...(practitionerFhir?.getNPI?.()
      ? [
          {
            label: 'npi',
            value: practitionerFhir.getNPI(),
          },
        ]
      : []),
    ...(practitionerFhir?.getSSN?.()
      ? [
          {
            label: 'ssn',
            value: practitionerFhir.getSSN(),
          },
        ]
      : []),
    ...(practitionerFhir?.getMedicare?.()
      ? [
          {
            label: 'medicare',
            value: practitionerFhir.getMedicare(),
          },
        ]
      : []),
  ];

  const phones = orderTelecomByRank(practitionerFhir?.getPhones?.());

  const emails = orderTelecomByRank(practitionerFhir?.getEmails?.());

  const addresses = practitionerFhir?.address ?? [];
  const createdOn = practitionerFhir?.meta?.tag?.find?.(
    ({ code }) => code === PH_META_TAG_CREATED_ON_CODE
  )?.display;

  return (
    <PreviewDetailDrawer
      showTitleDivider={false}
      isLoading={isFetching}
      containerSx={{ width: '20vw', minWidth: 320 }}
      contentContainerSx={{ backgroundColor: '#ffffff' }}
      anchor="right"
      {...drawerProps}
    >
      <Box sx={{ marginTop: 3 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <PractitionerProfileImage
            practitionerProfileImage={practitionerFhir?.photo?.[0]}
            isEditable={false}
            avatarSx={{ width: 65, height: 65 }}
          />
          <Box sx={{ marginTop: commonVerticalSpacing }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginRight: 1 }}>
                {practitionerFhir?.getFullName?.() ?? ''}
              </Typography>

              <VerifiedDecorator isVerified={!!practitionerFhir?.isVerified?.()} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: '0.8rem', color: '#637381', textTransform: 'capitalize' }}
              >
                {practitionerFhir?.gender ?? ''}
              </Typography>
              {practitionerFhir?.birthDate ? (
                <>
                  <Typography
                    variant="body2"
                    sx={{
                      marginX: 1,
                      color: '#637381',
                      fontSize: '1.5rem',
                      position: 'relative',
                      top: -7,
                    }}
                  >
                    •
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.8rem', color: '#637381' }}>
                    {`${getAge(moment(practitionerFhir.birthDate ?? null))} years`}
                  </Typography>
                </>
              ) : null}
            </Box>
          </Box>
        </Box>
        <Button
          sx={{ height: 48, marginTop: 1 }}
          color="primary"
          fullWidth
          variant="contained"
          onClick={() => {
            window.open(`${CRS_PATH.provider.root}/${practitionerFhir?.id}`, '_blank');
          }}
        >
          {i18n('goToProfile', 'crs')}
        </Button>
        <Divider sx={{ borderStyle: 'dashed', marginTop: commonVerticalSpacing }} />
        <Box sx={{ marginTop: commonVerticalSpacing }}>
          {!!identifiers.length && (
            <SectionInfo title={i18n('practitioner.identifiersText', 'crs')}>
              {identifiers.map(({ label, value }) => (
                <InfoRow
                  key={`${label}-${value}-key`}
                  label={i18n(`practitioner.identifiers.${label}`, 'crs')}
                  value={value}
                />
              ))}
            </SectionInfo>
          )}
          <SectionInfo sx={{ paddingRight: px }} title={i18n('practitioner.general', 'crs')}>
            <InfoRowValueList
              label={i18n('practitioner.phones', 'crs')}
              values={phones.map(({ value }) => value)}
            />
            <InfoRowValueList
              label={i18n('practitioner.emails', 'crs')}
              values={emails.map(({ value }) => value)}
            />
            <InfoRowValueList
              label={i18n('practitioner.addresses', 'crs')}
              values={addresses.map((address) => formatUSAddress(address))}
              valueSx={{ whiteSpace: 'pre-line' }}
            />
            <InfoRow
              label={i18n(`practitioner.dob`, 'crs')}
              value={
                practitionerFhir?.birthDate
                  ? getFormattedPersonDOB(practitionerFhir?.birthDate)
                  : ''
              }
            />
            <InfoRowValueList
              label={i18n('practitioner.languages', 'crs')}
              values={
                practitionerFhir?.communication?.map?.(
                  ({ coding, text }) => coding?.[0]?.display ?? text ?? ''
                ) ?? []
              }
            />
            {!!createdOn && (
              <InfoRow
                label={i18n(`practitioner.added`, 'crs')}
                value={`${formatDate(moment(createdOn))} (${
                  getTimeDiff(moment(createdOn), moment())?.diffText
                })`}
              />
            )}
            {!!practitionerFhir?.meta?.lastUpdated && (
              <InfoRow
                label={i18n(`practitioner.modified`, 'crs')}
                value={`${formatDate(moment(practitionerFhir.meta.lastUpdated))} (${
                  getTimeDiff(moment(practitionerFhir.meta.lastUpdated), moment())?.diffText
                })`}
              />
            )}
          </SectionInfo>
        </Box>
      </Box>
    </PreviewDetailDrawer>
  );
};

export default PractitionerDrawer;
