import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import Row from './Row';
import { useSnackbar } from 'notistack';
import useTable from 'src/hooks/useTable';
import { Add } from '@mui/icons-material';
import { useMemo, useState } from 'react';
import { isEmpty, startCase } from 'lodash';
import Iconify from 'src/components/Iconify';
import { useQuery } from '@tanstack/react-query';
import {
  ObservationWrapper,
  WrappedObservation,
} from 'src/@nicheaim/fhir-base/wrappers/Observation';
import { TableHeadCustom } from 'src/components/table';
import { useObservations, useValueSet } from 'src/@nicheaim/fhir-react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { Observation } from 'src/@nicheaim/fhir-base/mappings/Observation';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { TABLE_HEAD_OBSERVATION } from 'src/sections/crs/common/table-head';
import useAddEntityRequestStates from 'src/hooks/useAddEntityRequestStates';
import { RegistryValueType, getRegistries } from 'src/services/api/registry';
import RegistryComponent, { ValueType } from 'src/components/RegistryComponent';
import {
  convertValueToValueSet,
  convertValueToValueSetExpansion,
} from 'src/sections/crs/common/common-utils';
import { v4 as uuidv4 } from 'uuid';
import { translateTableHead } from 'src/sections/crs/helpers/common';
import useLocales from 'src/hooks/useLocales';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import {
  Coding,
  ValueSetComposeIncludeConcept,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

type Props = {
  patient: WrappedPatient | null;
};

export default function ObservationDetails({ patient }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [add, setOpenAdd] = useState(false);
  const [editRow, setEditRow] = useState({});
  const [typeObservation, setTypeObservation] = useState('');
  const [valueObservation, setValueObservation] = useState<{
    valueType: RegistryValueType | null;
    value: ValueType;
  }>({ valueType: null, value: null });
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({});

  const [valueSetType] = useValueSet('ph-type-observation', { map: ValueSetWrapper });
  const [valueSetCategory] = useValueSet('ph-category-observation', { map: ValueSetWrapper });
  const { i18n } = useLocales();

  const observationsFilter = useMemo(() => {
    const cats = valueSetCategory?.asList()?.map((item) => item?.code) ?? [];
    // const types = valueSetType?.asList()?.map((item) => item?.code) ?? [];
    const types = [];
    const filters = [...cats, ...types];

    const combinedFilter = filters && filters.length > 0 ? filters?.join(',') : '';

    const filter = {
      ...(patient?.id && { patient: patient?.id }),
      //...(combinedFilter && { category: combinedFilter }),
    };

    return filter;
  }, [patient?.id, valueSetCategory]);

  const canFetch = useMemo(() => {
    if (
      patient?.id &&
      observationsFilter &&
      observationsFilter?.patient //&& observationsFilter?.category
    ) {
      return true;
    }
    return false;
  }, [patient?.id, observationsFilter]);

  const [observations, { create: createObservation, update: updateObservation }] = useObservations({
    filter: observationsFilter,
    map: ObservationWrapper,
    autofetch: !!patient?.id && canFetch,
  });

  const [{ error }, { setError }] = useAddEntityRequestStates();

  const getValue = () => {
    const value =
      typeof valueObservation.value === 'object'
        ? valueObservation.value?.code
        : valueObservation.value;
    if (!value) return null;
    switch (valueObservation?.valueType) {
      case RegistryValueType.CodeableConcept:
        if (typeof valueObservation.value === 'object') {
          return {
            valueCodeableConcept: {
              coding: [valueObservation.value],
              text: valueObservation.value?.display,
            },
          };
        }
        return null;
      case RegistryValueType.NUMBER: {
        const integer = parseInt(String(value));
        if (isNaN(integer)) return null;
        return { valueInteger: integer };
      }
      default: {
        return { valueString: value };
      }
    }
  };

  const { data: registries } = useQuery({
    queryKey: ['registries', { groupIdentifier: 'observations' }],
    queryFn: async () => {
      const registries = await getRegistries('observations');
      return registries.data;
    },
  });

  const validateRequiredFields = (value: any) => {
    if (isEmpty(typeObservation) || isEmpty(value)) {
      return false;
    }
    return true;
  };

  const mapObservation = (derivedFrom?: string) => {
    const value = getValue() ?? {};

    if (!validateRequiredFields(value)) return setError('Please, fill all required fields');
    const conceptList = valueSetType?.asList?.() ?? [];

    const valueSetConcept = conceptList.find(({ code }) => code === typeObservation);

    const category = typeObservation.includes('score') ? 'inck-scores' : 'inck-barriers';
    const categoryCode = convertValueToValueSet(category, valueSetCategory);

    return {
      resourceType: 'Observation',
      code: {
        coding: [
          {
            code: valueSetConcept?.code,
            display: valueSetConcept?.display,
            system: valueSetType?.getConceptSystem?.(),
          },
        ],
        text: valueSetConcept?.display,
      },
      ...(categoryCode && {
        category: [
          {
            coding: [categoryCode],
            text: startCase(categoryCode?.display),
          },
        ],
      }),
      effectiveDateTime: new Date().toISOString(),
      issued: new Date().toISOString(),
      status: 'preliminary',
      subject: {
        reference: `Patient/${patient?.id}`,
      },
      ...value,
      ...(derivedFrom && {
        derivedFrom: [
          {
            reference: `Observation/${derivedFrom}`,
            type: 'Observation',
          },
        ],
      }),
    };
  };

  const saveObservation = async () => {
    try {
      if (isEmpty(editRow)) {
        const observation = mapObservation() as Observation;
        if (observation) {
          const result = await createObservation(observation);
          enqueueSnackbar('Observation was created.');
          handleClose();
        }
      } else {
        const observationEdit = editRow as Observation;
        observationEdit.status = 'amended';
        const createDerivedObservation = mapObservation(observationEdit?.id) as Observation;
        if (createDerivedObservation) {
          const resultCreate = await createObservation(createDerivedObservation);
          const resultUpdate = await updateObservation(observationEdit);
          enqueueSnackbar('Observation was updated.');
          handleClose();
        }
      }
    } catch (error) {
      enqueueSnackbar('Observation was not created.', { variant: 'error' });
      handleClose();
    }
  };

  const handleClose = () => {
    setTypeObservation('');
    setEditRow({});
    setOpenAdd(false);
    setError(null);
  };

  const handleEdit = (data: any) => {
    setEditRow(data);
    setTypeObservation(data?.code?.coding?.[0].code);
    setOpenAdd(true);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            <Stack sx={{ m: 1 }}>
              <Grid container display={'flex'} alignItems={'center'} paddingRight={2}>
                <Grid item xs={10}>
                  {/* <Typography variant="subtitle1">Observations</Typography> */}
                </Grid>
                {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.OBSERVATION.ADD] }) && (
                  <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
                    <Button
                      size="small"
                      sx={{ height: '36px' }}
                      startIcon={<Add />}
                      style={{ position: 'absolute', top: '25px', right: '25px' }}
                      onClick={() => setOpenAdd(true)}
                    >
                      {i18n('patients.details.observations.button', 'crs')}
                    </Button>
                  </Grid>
                )}
              </Grid>
              {add && (
                <>
                  <Grid
                    item
                    xs={12}
                    display={'flex'}
                    justifyContent={'center'}
                    sx={{ mx: 20, my: 2 }}
                  >
                    <FormControl sx={{ marginRight: 2 }} fullWidth error={!!error}>
                      <InputLabel id="observation">
                        {i18n('patients.details.observations.observations', 'crs')}
                      </InputLabel>
                      <Select
                        label="observation"
                        value={typeObservation}
                        onChange={(event) => {
                          setTypeObservation(event.target.value);
                          setError(null);
                        }}
                      >
                        {(valueSetType?.asList?.() ?? ([] as ValueSetComposeIncludeConcept[])).map(
                          (option) => (
                            <MenuItem key={option.code} value={option.code}>
                              {option.display}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>

                    <RegistryComponent
                      disabled={!typeObservation}
                      registries={registries ?? []}
                      label={i18n('patients.details.observations.value', 'crs')}
                      keyRegistry={typeObservation}
                      onValueChange={(value, valueType) => {
                        setValueObservation({ value, valueType });
                        setError(null);
                      }}
                      error={error}
                    />

                    <IconButton sx={{ my: 1, ml: 2 }} onClick={saveObservation}>
                      <Iconify icon="eva:save-outline" />
                    </IconButton>
                    <IconButton sx={{ my: 1 }} onClick={handleClose}>
                      <Iconify icon="ic:outline-close" />
                    </IconButton>
                  </Grid>
                  {error && (
                    <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                      <FormHelperText sx={{ color: '#d50000' }}>{error}</FormHelperText>
                    </Grid>
                  )}
                </>
              )}
            </Stack>
            <Table size="small" sx={{ mb: 2 }}>
              <TableHeadCustom headLabel={translateTableHead(TABLE_HEAD_OBSERVATION, 'crs')} />
              <TableBody>
                {!isEmpty(observations) ? (
                  observations
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: WrappedObservation) => (
                      <Row key={uuidv4()} row={row} handleEdit={handleEdit} />
                    ))
                ) : (
                  <TableCell colSpan={TABLE_HEAD_OBSERVATION?.length}>
                    <Typography variant="body2" align="center">
                      No rows
                    </Typography>
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={observations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </>
  );
}
