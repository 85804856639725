import { 
  Backdrop,
  Box,
  Button, 
  Card, 
  CircularProgress, 
  Divider, 
  Drawer, 
  Grid, 
  List, 
  ListItem, 
  Stack, 
  Typography 
} from "@mui/material";
import { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { wait } from "src/utils/timers";
import { capitalCase } from "change-case";
import { WrappedTask } from "src/@nicheaim/fhir-base/wrappers/Task";
import { CheckCircleOutline as CheckIcon } from "@mui/icons-material";
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";

type Props = {
  patient: WrappedPatient | null;
  tasks: WrappedTask[] | null;
  isOpen: boolean;
  refreshTasks: Function,
  handlerIsOpen: Function;
  handleCompleteHJRequest: () => Promise<any>;
}

export default function TasksList({
  patient,
  tasks,
  isOpen,
  refreshTasks,
  handlerIsOpen,
  handleCompleteHJRequest
}: Props) {

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handlerStep = async () => {
    setOpenBackdrop(true);
    await wait();
    handleCompleteHJRequest();
    refreshTasks();
    setOpenBackdrop(false);
  };

  const getOwnerType = (reference: string): string => {
    let type: any = "";
  
    switch (reference) {
      case "Patient":
        type = "Member";
        break;
      case "RelatedPerson":
        type = "Parent/Caregiver";
        break;
      default:
        type = "";
    }
    return type;
  };

  return (
    <Drawer
      PaperProps={{
        sx: { width: "60%" },
      }}
      anchor="left"
      open={isOpen}
      onClose={() => {
        handlerIsOpen(false);
      }}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <List component="nav">
        <ListItem>
          <Grid container>
            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ width: '100%' }}>
              <Button
                onClick={() => {
                  handlerIsOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handlerStep}
                variant="contained"
              >
                Check again
              </Button>
            </Stack>
          </Grid>
        </ListItem>
      </List>

      <Divider sx={{ marginBottom: 2 }} />

      <Grid container spacing={3} direction="row">
        <Grid
          item
          md={12}
          width="100%"
          container
          flexDirection="column"
          spacing={2}
          className="request-details-container"
        >
          {tasks?.map((item: any) => (
            <Card key={uuidv4()} sx={{ mx: 4, my: 2 }}>
              <Box sx={{ 
                  display: 'flex', 
                  p: 1, 
                  flexDirection: 'row', 
                  backgroundColor:'#1890FF',
                  alignItems: 'center',
                }}
              >
                <Typography 
                  variant='h6' 
                  sx={{ color: 'white', margin: 'auto' }}>
                    Task: {item?.getTaskNumberNAT()}
                </Typography>
                {item?.status === 'completed' && (
                  <CheckIcon 
                    fontSize="medium" 
                    sx={{ color: "white", marginRight: '20px' }} 
                  />
                )}
              </Box>
              <Box sx={{ 
                  display: 'flex', 
                  p: 1, 
                  flexDirection: 'column', 
                }}
              >
                <Typography 
                  variant='button'>
                    Status: {item?.status && capitalCase(item.status)}
                </Typography> 
                <Typography 
                  variant='button'>
                    Name: {patient?.getFullName()}
                </Typography>
                <Typography 
                  variant='button'>
                    Type: {item?.owner?.reference && getOwnerType(item?.owner?.reference.split('/')[0])}
                </Typography>
              </Box>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Drawer>
  );
}