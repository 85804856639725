import { useState } from 'react';
import { GridState, PatientList, UsersView } from './PatientList';
import { PaginatedPatientDto } from 'src/crs/patient/services/patientService';

const initialGridState: GridState = {
  page: 1,
  rowsPerPage: 5,
  orderBy: 'createdAt',
  orderDirection: 'desc',
  search: '',
  // silFilter: [...initialServiceFilter],
  silFilter: [],
  ownersToFilter: [],
};

const PatientListContainer = () => {
  const [tableData, setTableData] = useState<PaginatedPatientDto | undefined | null>(null);
  const [search, setSearch] = useState<string>('');
  const [totalItems, setTotalItems] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ownersToFilter, setOwnersToFilter] = useState<UsersView[] | null>(null);

  return (
    <PatientList
      includeBreadcrumbs={true}
      tableData={tableData}
      setTableData={setTableData}
      search={search}
      setSearch={setSearch}
      totalItems={totalItems}
      setTotalItems={setTotalItems}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      initialGridState={initialGridState}
      ownersToFilter={ownersToFilter}
      setOwnersToFilter={setOwnersToFilter}
    />
  );
};

export default PatientListContainer;
