import { 
  Backdrop,
  Button, 
  CircularProgress, 
  Divider, 
  Drawer, 
  Grid, 
  InputLabel, 
  List, 
  ListItem, 
  MenuItem, 
  Stack,  
} from "@mui/material";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import { useValueSet } from "src/@nicheaim/fhir-react";
import { ValueSetWrapper } from "src/@nicheaim/fhir-base/wrappers/ValueSet";
import { FormProvider, RHFSelect, RHFTextField } from "src/components/hook-form";

type FormValues = {
  opt_out_decision: string;
  type: string;
  notes: string;
};

type Props = {
  isOpen: boolean;
  handlerIsOpen: Function;
  handlerConfirmCMOptIN: (data:any) => Promise<any>;
};

export default function ConfirmCMOptInItem({
  isOpen,
  handlerIsOpen,
  handlerConfirmCMOptIN,
}: Props) {
  
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [optTypes] = useValueSet('opt-in-types', { map: ValueSetWrapper });
  const [optDecisions] = useValueSet('opt-in-decisions', { map: ValueSetWrapper });

  const EventSchema = Yup.object().shape({
    opt_out_decision: Yup.string().required('Opt-Out Decision is required'),
    type: Yup.string().required('Type is required'),
  });

  const methods = useForm<FormValues>({ resolver: yupResolver(EventSchema)});

  const {
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    reset({
      opt_out_decision: '',
      type: '',
      notes: ''
    })
  }, [isOpen]);

  const onSubmit = async (data: FormValues) => {

    setOpenBackdrop(true);

    try {
      await handlerConfirmCMOptIN(data);
    } catch (error) {console.log(error)}
    
    handlerIsOpen(false);  
    setOpenBackdrop(false);
  };

  return (
    <>
      <Drawer
        PaperProps={{
          sx: { width: "60%" },
        }}
        anchor="left"
        open={isOpen}
        onClose={() => {
          handlerIsOpen(false);
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <List component="nav">
            <ListItem>
              <Grid container>
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    type="submit"
                  >
                    Confirm CM Opt-Out
                  </Button>
                  
                  <Button
                    onClick={() => {
                      handlerIsOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Grid>
            </ListItem>
          </List>
          <Divider sx={{ marginBottom: 2 }} />
          <Grid
            container
            direction="column"
            alignItems="center"
            spacing={2}
            xs={10}
            ml={2}
            mr={2}
          >
            <Grid container item direction="row" alignItems="center">
              <Grid container item xs={3} justifyContent="start">
                <InputLabel htmlFor="opt_out_decision">
                Opt-Out Decision
                </InputLabel>
              </Grid>
              <Grid container item xs={9} justifyContent="start">
                <RHFSelect name="opt_out_decision" label="" fullWidth={true}>
                  <MenuItem key={""} value={""} disabled></MenuItem>
                  {optDecisions?.asList().map((option) => (
                    <MenuItem key={option.code} value={option.display}>
                      {option.display}
                    </MenuItem>
                  ))}
                </RHFSelect> 
              </Grid>
            </Grid>

            <Grid container item direction="row" alignItems="center">
              <Grid container item xs={3} justifyContent="start">
                <InputLabel htmlFor="type">Type</InputLabel>
              </Grid>
              <Grid container item xs={9} justifyContent="start">
                <RHFSelect name="type" label="" fullWidth={true}>
                  <MenuItem key={""} value={""} disabled></MenuItem>
                  {optTypes?.asList().map((option) => (
                    <MenuItem key={option.code} value={option.display}>
                      {option.display}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
            </Grid>

            <Grid container item direction="row" alignItems="center">
              <Grid container item xs={3} justifyContent="start">
                <InputLabel htmlFor="notes">Notes</InputLabel>
              </Grid>
              <Grid container item xs={9} justifyContent="start">
                <RHFTextField name="notes" label="Notes" multiline rows={2} fullWidth={true}/>
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </Drawer>
    </>
  );
}
