import { PatientManager } from 'src/@types/crs/patient';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { useNavigate, useLocation } from 'react-router-dom';
import { TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { Person as PersonIcon, Cake as CakeIcon } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import ViewComfyOutlinedIcon from '@mui/icons-material/ViewComfyOutlined';
import CustomLink from '../../common/CustomLink';
import useLocales from 'src/hooks/useLocales';
import MRNIcon from '../../../../assets/icons/mrn.svg';
import MedicaIdIcon from '../../../../assets/icons/medicaid.svg';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

type Props = {
  row: PatientManager;
};

export default function PatientRow({ row }: Props) {
  const {
    id,
    mrn,
    referral,
    phone,
    email,
    gender,
    formattedBirthDate,
    age,
    medicaId,
    sil,
    createdAt,
    lastUpdated,
  } = row;

  const { componentsData } = useTenantConfigData();
  const { showSILInformation } = componentsData ?? {};
  const navigate = useNavigate();
  const location = useLocation();

  const { i18n } = useLocales();

  return (
    <>
      <TableRow>
        <TableCell>
          <Tooltip title={i18n('patients.mrn', 'crs')}>
            <Typography variant="subtitle2">
              <MRNIcon style={{ height: 13, width: 13, marginRight: 9 }} />
              <CustomLink to={PATH_DASHBOARD.crs.patient.details.forId(row.id)}>{mrn}</CustomLink>
            </Typography>
          </Tooltip>
          <Tooltip title={i18n('patients.medicaid', 'crs')}>
            <Typography variant="body2">
              <MedicaIdIcon style={{ height: 13, width: 16, marginRight: 9 }} />
              {medicaId ? medicaId : null}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2">
            <PersonIcon sx={{ marginRight: 1, marginBottom: '-2px' }} fontSize={'small'} />{' '}
            <CustomLink to={PATH_DASHBOARD.crs.patient.details.forId(row.id)}>
              {referral}
            </CustomLink>
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            <CakeIcon
              sx={{ marginRight: 1, color: '#919eab', marginBottom: '-2px' }}
              fontSize={'small'}
            />
            {formattedBirthDate ? `${formattedBirthDate} (${age})` : null}
          </Typography>
          <Typography variant="body2">
            {gender && `${gender?.charAt(0).toUpperCase()}${gender?.slice(1)}`}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            <PhoneIcon
              sx={{ marginRight: 1, color: '#919eab', marginBottom: '-2px' }}
              fontSize={'small'}
            />
            {phone}
          </Typography>
          <Typography variant="body2">
            <EmailIcon
              sx={{ marginRight: 1, color: '#919eab', marginBottom: '-5px' }}
              fontSize={'small'}
            />
            {email}
          </Typography>
        </TableCell>
        {!!showSILInformation && (
          <TableCell>
            {!!sil && (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                <ViewComfyOutlinedIcon
                  sx={{ marginRight: 1, color: '#919eab', marginBottom: '-2px' }}
                  fontSize={'small'}
                />
                SIL Score: {sil ? sil : null}
              </Typography>
            )}
          </TableCell>
        )}
        <TableCell>
          <Typography variant="body2">Registration Date: {createdAt ? createdAt : null}</Typography>
          <Typography variant="body2">Last Updated: {lastUpdated ? lastUpdated : '-'}</Typography>
        </TableCell>
      </TableRow>
    </>
  );
}
