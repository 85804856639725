import { Component, useMemo } from 'react';
import { Chip, Grid, GridProps, Stack, SxProps, Typography } from '@mui/material';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { capitalCase } from 'change-case';
import useLocales from 'src/hooks/useLocales';
import { spreadSxProp } from 'src/utils/cssStyles';
import useScores from './useScores';
import useTenantConfigData, { BannerInfoName } from 'src/hooks/useTenantConfigData';

type PatientBasicInfoProps = {
  patient: WrappedPatient;
};

export type BannerInfoComponents = {
  [key in BannerInfoName]?: () => React.ReactNode;
};

export function PatientBasicInfo({ patient }: PatientBasicInfoProps) {
  const { i18n } = useLocales();
  const { sil } = useScores({ patientId: patient?.id });
  const { componentsData } = useTenantConfigData();
  const { showSILInformation } = componentsData ?? {};

  const bannerInfoComponents: BannerInfoComponents = useMemo(
    () => ({
      [BannerInfoName.MRN]: () => (
        <InfoComponent
          label={i18n('patients.mrn', 'crs')}
          value={patient.getMRN()?.value}
          key={'bannedInfoNameMRN'}
        />
      ),
      [BannerInfoName.AGE]: () => (
        <InfoComponent
          label={i18n('patients.age', 'crs')}
          value={`${patient.getAgeInYears()} years`}
          key={'bannedInfoNameAge'}
        />
      ),
      [BannerInfoName.GENDER]: () => (
        <InfoComponent
          label={i18n('patients.gender', 'crs')}
          value={patient.gender}
          valueSx={{ textTransform: 'capitalize' }}
          key={'bannedInfoNameGender'}
        />
      ),
      [BannerInfoName.PRIMARY_PHONE]: () => (
        <InfoComponent
          label={i18n('patients.phone', 'crs')}
          value={patient.getPrimaryPhone()?.value}
          key={'bannedInfoNamePrimaryPhone'}
        />
      ),
      [BannerInfoName.PRIMARY_EMAIL]: () => (
        <InfoComponent
          label={i18n('patients.email', 'crs')}
          value={patient.getPrimaryEmail()?.value}
          key={'bannedInfoNamePrimaryEmail'}
        />
      ),
      [BannerInfoName.MEDICAID]: () => (
        <InfoComponent
          label={i18n('patients.medicaid', 'crs')}
          value={patient.getMedicaId()}
          key={'bannedInfoNameMedicaid'}
        />
      ),
      [BannerInfoName.PRIMARY_ZIPCODE]: () => (
        <InfoComponent
          label={i18n('patients.details.addressDetails.zipCode', 'crs')}
          value={patient.getZIPCode()}
          key={'bannedInfoNamePrimaryZipCode'}
        />
      ),
      [BannerInfoName.SIL_SCORE]: () =>
        showSILInformation ? (
          <InfoComponent
            label={i18n('patients.sil', 'crs')}
            value={String(sil?.getValue?.())}
            key={'bannedInfoNameSilScope'}
          />
        ) : null,
    }),
    [patient, sil]
  );

  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ my: 1 }} spacing={2}>
        <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
          {`${patient?.name?.[0]?.given?.[0]} 
            ${patient?.name?.[0]?.given?.[1] !== undefined ? patient?.name?.[0]?.given?.[1] : ''} 
            ${patient?.name?.[0]?.family}`}
        </Typography>
        <Chip
          size="small"
          label={capitalCase(patient.getStatus())}
          color={(patient.getStatus() === 'active' && 'info') || 'error'}
        />
      </Stack>
      <Grid spacing={2} container>
        {Object.entries(bannerInfoComponents).map(([_, renderComponent]) => renderComponent())}
      </Grid>
    </>
  );
}

export interface InfoComponentProps extends GridProps {
  label: string | null | undefined;
  value: string | null | undefined;
  containerSx?: SxProps;
  labelSx?: SxProps;
  valueSx?: SxProps;
}

const InfoComponent = ({
  label,
  value,
  containerSx,
  labelSx,
  valueSx,
  ...gridProps
}: InfoComponentProps) => (
  <Grid
    item
    sx={[{ display: 'flex', flexDirection: 'row' }, ...spreadSxProp(containerSx)]}
    {...gridProps}
  >
    <Typography
      variant="body2"
      sx={[{ color: 'text.secondary', mr: 1, whiteSpace: 'nowrap' }, ...spreadSxProp(labelSx)]}
    >
      {label ?? ''}:
    </Typography>
    <Typography variant="body2" sx={[{ whiteSpace: 'nowrap' }, ...spreadSxProp(valueSx)]}>
      {value ?? ''}
    </Typography>
  </Grid>
);
