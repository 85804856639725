import * as Yup from 'yup';
import produce from 'immer';
import moment from 'moment';
import useAuth from 'src/hooks/useAuth';
import { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useValueSet } from 'src/@nicheaim/fhir-react';
import { MobileDatePicker, MobileDateTimePicker } from '@mui/lab';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import {
  adminAll,
  checkAclValidation,
  SysAdmin,
} from 'src/utils/permissions/permission.utils';
import {
  Backdrop,
  Button,
  CircularProgress,
  Drawer,
  Grid,
  List,
  ListItem,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  ContactAttemptOutcome,
  PatientEngagementContactAttemptDto,
} from 'src/engagement/patient/dto/patient-engagment-contact-attempt.dto';

type ConfirmContactProps = {
  patient: WrappedPatient | null;
  patientEngagementId: string;
  isOpen: boolean;
  handlerIsOpen: Function;
  handlePatient: (data: any) => Promise<any>;
  handleConfirmContactAttempt: (data: any) => Promise<any>;
  refreshPatient: () => Promise<any>;
};

export default function ConfirmContact({
  patient,
  patientEngagementId,
  isOpen,
  handlerIsOpen,
  handlePatient,
  handleConfirmContactAttempt,
  refreshPatient,
}: ConfirmContactProps) {
  const user = useAuth().getCurrentUser();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState<any>(false);
  const [disableInckEligible, setDisableInckEligible] = useState<any>(false);
  const [outcomeList] = useValueSet('ph-outcome-contact-form', { map: ValueSetWrapper });

  useEffect(() => {
    if (isOpen)
      reset({
        contactOn: '',
        nextContactOn: '',
        outcome: '',
        duration: '',
        reasonNote: '',
      });
  }, [isOpen]);

  const schema = Yup.object().shape({
    contactOn: Yup.date()
      .required('Contact On is required')
      .nullable()
      .transform((curr, orig) => (moment(orig).isValid() ? curr : null)),
    nextContactOn: Yup.date()
      .required('nextContactOn is required')
      .nullable()
      .transform((curr, orig) => (moment(orig).isValid() ? curr : null))
      .min(Yup.ref('contactOn'), "Contact On can't be greater than Next Contact On"),
    outcome: Yup.string().required('Outcome is required'),
    duration: Yup.string().required('Duration is required'),
    reasonNote: Yup.string().required('Note is required'),
  });

  const methods = useForm({ resolver: yupResolver(schema) });

  const { control, reset, handleSubmit } = methods;

  const inckEligible = useMemo(() => patient?.getInckEligible(), [patient]);

  const handleInckEligible = async () => {
    setOpenBackdrop(true);
    setSaveBtnDisabled(true);
    setDisableInckEligible(true);

    let meta = patient?.meta;

    if (patient && meta) {
      if (inckEligible) {
        meta.tag = meta.tag?.filter((tag) => tag?.code !== 'InCK-Eligible');
        if (meta?.tag?.length === 0) delete meta.tag;
      } else {
        if (!meta.tag) {
          meta.tag = [];
        }
        meta.tag.push({
          code: 'InCK-Eligible',
          display: 'InCK-Eligible',
          system: `${process.env.REACT_APP_FHIR_API_BASE_URL}/ValueSet/eligibility`,
        });
      }
    }

    await handlePatient(
      produce(patient!, (draft) => {
        draft.meta = meta;
      })
    );

    await refreshPatient();

    setDisableInckEligible(false);
    setSaveBtnDisabled(false);
    setOpenBackdrop(false);
  };

  const mapFormDataToCommunication = (data: any): PatientEngagementContactAttemptDto => {
    const { contactOn, nextContactOn, outcome, duration, reasonNote } = data;

    return {
      typeNote: 'Contact Note',
      reasonNote: reasonNote,
      outcome: outcome as ContactAttemptOutcome,
      id: Number(patientEngagementId),
      contactOn: new Date(contactOn).toISOString(),
      nextContactOn: new Date(nextContactOn).toString(),
      duration: duration,
    };
  };

  const onSubmit = async (values: any) => {
    setOpenBackdrop(true);
    setSaveBtnDisabled(true);
    try {
      const resultMap = mapFormDataToCommunication(values);
      await handleConfirmContactAttempt(resultMap);
    } catch (error) {}
    setSaveBtnDisabled(false);
    handlerIsOpen(false);
    setOpenBackdrop(false);
  };

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={() => {
        handlerIsOpen(false);
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <List component="nav">
          <ListItem>
            <Grid width={800} container justifyContent="space-between">
              <Stack direction="row" spacing={2}>
                <Button
                  onClick={() => {
                    handlerIsOpen(false);
                  }}
                >
                  Cancel
                </Button>

                {patient &&
                  checkAclValidation({                  
                    acls: [SysAdmin, adminAll],
                  }) && (
                    <Grid item container justifyContent="flex-end" sx={{ marginTop: '-24px' }}>
                      <Button
                        variant="contained"
                        color={inckEligible ? 'error' : 'primary'}
                        onClick={() => handleInckEligible()}
                        disabled={disableInckEligible}
                      >
                        {inckEligible ? 'Un-Enroll' : 'Enroll'}
                      </Button>
                    </Grid>
                  )}
              </Stack>
              <Grid container spacing={3} sx={{ marginTop: 5 }}>
                <Grid item md={12}>
                  <Typography variant="subtitle1" component="div" sx={{ marginBottom: 2 }}>
                    Contact On
                  </Typography>
                  <Controller
                    name="contactOn"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <MobileDateTimePicker
                        {...field}
                        label="Contact On"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12}>
                  <Typography variant="subtitle1" component="div" sx={{ marginBottom: 2 }}>
                    Next Contact On
                  </Typography>
                  <Controller
                    name="nextContactOn"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <MobileDatePicker
                        {...field}
                        label="Next Contact On"
                        minDate={new Date()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12}>
                  <Typography variant="subtitle1" component="div" sx={{ marginBottom: 2 }}>
                    Outcome
                  </Typography>
                  <RHFSelect name="outcome" label="" fullWidth={true}>
                    {outcomeList?.asList().map((option: any) => (
                      <MenuItem key={option.code} value={option.display}>
                        {option.display}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </Grid>
                <Grid item md={12}>
                  <Typography variant="subtitle1" component="div" sx={{ marginBottom: 2 }}>
                    Duration
                  </Typography>
                  <RHFTextField name="duration" label="" />
                </Grid>
                <Grid item md={12}>
                  <Typography variant="subtitle1" component="div" sx={{ marginBottom: 2 }}>
                    Add Note
                  </Typography>
                  <RHFTextField name="reasonNote" label="Add Note" multiline rows={4} />
                </Grid>
                <Grid item md={12}>
                  <Grid container justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="success"
                      disabled={saveBtnDisabled}
                      type="submit"
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </FormProvider>
    </Drawer>
  );
}
