import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TextField,
} from '@mui/material';
import useTable from '../../../hooks/useTable';
import { PatientManager } from 'src/@types/crs/patient';
import { PatientRow } from '.';
import { TableToolbar } from '../common';
import { TableHeadCustom } from 'src/components/table';
import { PatientLayout } from '../PatientLayout';
import { patientService } from 'src/crs/patient/services';
import { PaginateQuery, PaginateQueryWithStats } from 'src/api/pagination/dtos';
import { PatientDto } from 'src/crs/dto/patient/patient.dto';
import { ACLValidationMode, checkAclValidation } from 'src/utils/permissions/permission.utils';
import { TABLE_HEAD_PATIENT_LIST } from '../common/table-head';
import NoResultsTableRow from '../common/NoResultsTableRow';
import LoadingTableRow from '../../../components/table/LoadingTableRow';
import { debounce, wait } from 'src/utils/timers';
import { Tooltip, IconButton, Typography } from '@mui/material';
import FilterDrawer from 'src/components/FilterDrawer';
import useObjectState from 'src/hooks/useObjectState';
import { ValueSetComposeIncludeConcept } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import useLocales from 'src/hooks/useLocales';
import { translateTableHead } from '../helpers/common';
import useNotiMessage from 'src/hooks/useNotiMessage';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import moment from 'moment';
import { getFhirIdFromReferenceString } from 'src/sections/careflow/tasks-activities/components/Activities/activities-utils';
import { isEmpty } from 'lodash';
import agent from 'src/api/agent';
import {
  Person as PersonIcon,
  Cake as CakeIcon,
  FilterList as FilterListIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  ContactEmergency,
  Today as TodayIcon,
  Event as EventIcon,
} from '@mui/icons-material';
import Iconify from 'src/components/Iconify';
import useTenantConfigData from 'src/hooks/useTenantConfigData';
import AddPatientModal from './components/AddPatientModal';
import { PaginatedPatientDto } from '../../../crs/patient/services/patientService';

export const PatientHeaderColumn = [
  {
    headerColumn: 'id',
    value: 'indexedMrn',
    label: 'MRN',
    icon: (
      <Iconify
        icon="mdi:letter-m"
        sx={{ fontSize: '2.2rem', color: '#919eab', marginBottom: '-10px', marginLeft: '-7px' }}
      />
    ),
  },
  {
    headerColumn: 'id',
    value: 'indexedCustomIdentifierMedicaidId',
    label: 'Medicaid ID',
    icon: (
      <ContactEmergency
        sx={{ marginRight: 1, color: '#919eab', marginBottom: '-3px' }}
        fontSize={'small'}
      />
    ),
  },
  {
    headerColumn: 'patient',
    value: 'indexedGivenName',
    label: 'Name',
    icon: (
      <PersonIcon
        sx={{ marginRight: 1, color: '#919eab', marginBottom: '-3px' }}
        fontSize={'small'}
      />
    ),
  },
  {
    headerColumn: 'patient',
    value: 'indexedDob',
    label: 'Date of Birth',
    icon: (
      <CakeIcon
        sx={{ marginRight: 1, color: '#919eab', marginBottom: '-2px' }}
        fontSize={'small'}
      />
    ),
  },
  {
    headerColumn: 'contact',
    value: 'indexedPhone',
    label: 'Phone',
    icon: (
      <PhoneIcon
        sx={{ marginRight: 1, color: '#919eab', marginBottom: '-3px' }}
        fontSize={'small'}
      />
    ),
  },
  {
    headerColumn: 'contact',
    value: 'indexedEmail',
    label: 'Email',
    icon: (
      <EmailIcon
        sx={{ marginRight: 1, color: '#919eab', marginBottom: '-4px' }}
        fontSize={'small'}
      />
    ),
  },
  {
    headerColumn: 'dates',
    value: 'indexedCreatedAt',
    label: 'Registration Date',
    icon: (
      <TodayIcon
        sx={{ marginRight: 1, color: '#919eab', marginBottom: '-4px' }}
        fontSize={'small'}
      />
    ),
  },
  {
    headerColumn: 'dates',
    value: 'indexedMetaLastUpdatedOnUtc',
    label: 'Last Updated',
    icon: (
      <EventIcon
        sx={{ marginRight: 1, color: '#919eab', marginBottom: '-4px' }}
        fontSize={'small'}
      />
    ),
  },
];

export const initialServiceFilter = [
  {
    code: '1',
    display: 'Service Integration Level 1',
  },
  {
    code: '2',
    display: 'Service Integration Level 2',
  },
  {
    code: '3',
    display: 'Service Integration Level 3',
  },
];
export type UsersView = { code: string; label: string };

export interface GridState {
  page: number;
  rowsPerPage: number;
  orderBy: string | null;
  orderDirection: 'asc' | 'desc' | null;
  search: string;
  silFilter: ValueSetComposeIncludeConcept[];
  ownersToFilter: UsersView[];
}

interface PatientListProps {
  includeBreadcrumbs?: boolean;
  tableData: PaginatedPatientDto | null | undefined;
  setTableData: Dispatch<SetStateAction<PaginatedPatientDto | null | undefined>>;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  totalItems: number;
  setTotalItems: Dispatch<SetStateAction<number>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  initialGridState: GridState;
  ownersToFilter: UsersView[] | null;
  setOwnersToFilter: Dispatch<SetStateAction<UsersView[] | null>>;
  includeStats?: boolean;
  setStats?: Dispatch<SetStateAction<any>>;
  show?: boolean;
  showFilters?: boolean;
}

export function PatientList({
  includeBreadcrumbs,
  tableData,
  setTableData,
  search,
  setSearch,
  totalItems,
  setTotalItems,
  initialGridState,
  isLoading,
  setIsLoading,
  ownersToFilter,
  setOwnersToFilter,
  includeStats = false,
  setStats,
  show = true,
  showFilters = true,
}: PatientListProps) {
  const { i18n } = useLocales();
  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    setPage,
    onSort,
    setOrder,
  } = useTable({
    defaultRowsPerPage: 5,
    defaultOrderBy: 'id',
    initialIndex: 1,
    defaultCurrentPage: 1,
  });
  const { componentsData } = useTenantConfigData();
  const { showSILInformation } = componentsData ?? {};
  const [gridState, updateGridState] = useObjectState<GridState>(initialGridState);
  const [filterMrn, setFilterMrn] = useState('');

  const [silFilters, setSilFilters] = useState();
  const [isOpenAplyFilters, setIsOpenAplyFilters] = useState<boolean>(false);
  const { showErrorMessage } = useNotiMessage();
  const [users, setUsers] = useState<UsersView[] | null>(null);
  const [isAddPatientModalOpen, setIsAddPatientModalOpen] = useState(false);

  const getPaginateQuery = (
    page: number,
    rowsPerPage: number,
    search: string,
    silFilters: any,
    orderBy: [string, string],
    ownersToFilter: UsersView[] | null,
    includeStats?: boolean
  ) => {
    const paginateQuery: PaginateQueryWithStats = { page: page, limit: rowsPerPage };
    if (search) {
      paginateQuery.search = search;
    }
    paginateQuery.filter = {};

    const [field, order] = orderBy;

    if (field && order) {
      paginateQuery.sortBy = [[field, order]];
    }

    if (silFilters?.length) {
      paginateQuery.filter.indexedCustomLastSil = `$in:${silFilters
        .map(({ code }: { code: any }) => code)
        .join(',')}`;
    }

    // if (ownersToFilter && ownersToFilter?.length > 0) {
    //   paginateQuery.filter.indexedCareTeamsPractitioners = `${ownersToFilter
    //     .map(({ code }: { code: any }) => code)
    //     .join(',')}`;
    // }

    if (ownersToFilter && ownersToFilter?.length > 0) {
      paginateQuery.filter.indexedGeneralPractitioners = `${ownersToFilter
        .map(({ code }: { code: any }) => code)
        .join(',')}`;
    }

    if (includeStats) {
      paginateQuery.includeStats = true;
    }

    return paginateQuery;
  };

  const getAllUsers = async () => {
    const allUsers = await agent.User.getAllUsers();
    const mapUsers = allUsers
      ?.filter((x: any) => !isEmpty(x.fhirUri))
      .map((item: any) => ({
        code: getFhirIdFromReferenceString(item?.fhirUri),
        label: `${item?.firstName} ${item?.lastName}`,
      }));
    setUsers(mapUsers);
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const fetchPatientList = async (
    page: number,
    rowsPerPage: number,
    search: string,
    silFilters: any,
    orderBy: [string, string],
    ownersToFilter: UsersView[] | null
  ) => {
    setIsLoading(true);
    await wait();
    const paginateQuery = getPaginateQuery(
      page,
      rowsPerPage,
      search,
      silFilters,
      orderBy,
      ownersToFilter,
      includeStats
    );
    const patients = await patientService.getAll(paginateQuery);
    setIsLoading(false);
    if (!patients) return showErrorMessage(i18n('error.patient.list'));

    if (patients?.meta?.totalItems !== undefined) setTotalItems(patients?.meta?.totalItems);

    setTableData(patients);

    if (includeStats && setStats && patients?.stats) {
      setStats(patients?.stats);
    }
  };

  const handlerClear = () => {
    updateGridState({
      ownersToFilter: [],
      silFilter: undefined,
    });
    setOwnersToFilter(null);
    setSilFilters(undefined);
  };

  const getPatientList = useCallback(debounce(fetchPatientList, 600), []);

  const handlePageChange = (event: unknown, newPage: number) => {
    onChangePage(event, newPage + 1);
  };

  const handleSearch = (query: string) => {
    setSearch(query);
    setPage(1);
  };

  useEffect(() => {
    getPatientList(page, rowsPerPage, search, silFilters, [orderBy, order], ownersToFilter);
  }, [page, rowsPerPage, filterMrn, search, order, orderBy, ownersToFilter]);

  const patientDtoToPatientManager = (ref: PatientDto): PatientManager => {
    const upAtFormat = moment(ref.indexedUpdatedAt).format('MMM DD, YYYY');
    const lastUpdatedFormat = ref.metaLastUpdated
      ? moment(ref.metaLastUpdated).format('MMM DD, YYYY')
      : null;
    const row = {
      id: ref.indexedFhirRefUri?.slice(
        ref.indexedFhirRefUri?.lastIndexOf('/') + 1,
        ref.indexedFhirRefUri?.length
      ),
      mrn: ref.indexedMrn,
      referral: ref.indexedFullName,
      phone: ref.indexedPhone,
      email: ref.indexedEmail,
      birthDate: ref.indexedDob,
      gender: ref.indexedGender,
      demographics: ref.indexedEthnicity,
      race: ref.indexedRace,
      registrationDate: ref.indexedCreatedAt,
      formattedBirthDate: ref.indexedDOBFormatted,
      age: ref.indexedAge,
      registrationDateFormatted: ref.indexedCreatedAtFormatted,
      medicaId: ref.indexedCustomIdentifierMedicaidId,
      sil: ref.indexedCustomLastSil,
      createdAt: ref.indexedCreatedAtFormatted,
      updatedAt: upAtFormat,
      lastUpdated: lastUpdatedFormat,
    };
    return row;
  };

  const dataFormatted = (): PatientManager[] => {
    const data = tableData?.data ? tableData.data.map((r) => patientDtoToPatientManager(r)) : [];
    return data;
  };

  const rows = dataFormatted();

  const tableHeadPatientListFilter = TABLE_HEAD_PATIENT_LIST.filter((column) => {
    return showSILInformation || column.id !== 'indexedCustomLastSil';
  });

  const headLabel = translateTableHead(tableHeadPatientListFilter, 'crs');

  if (!show) {
    return null;
  }

  return (
    <>
      <PatientLayout
        title={i18n('patients.list.title', 'crs')}
        button={
          <>
            {checkAclValidation(
              { acls: [crsAcls.CRS.PATIENT.MANUAL_ADD] },
              ACLValidationMode.EXCLUSIVE
            ) && (
              <Grid item xs={12}>
                <Stack alignItems="flex-end">
                  <Button
                    variant="contained"
                    onClick={() => {
                      setIsAddPatientModalOpen(true);
                    }}
                  >
                    {i18n('patients.list.addPatient', 'crs')}
                  </Button>
                </Stack>
              </Grid>
            )}
          </>
        }
        includeBreadcrumbs={includeBreadcrumbs}
      >
        <>
          <Grid item xs={12}>
            <Card>
              <Stack direction="row" sx={{ py: 2, px: 2 }}>
                <Grid xs={6}>
                  <TableToolbar filterMrn={search} onFilterMrn={handleSearch} />
                </Grid>
                {showFilters ? (
                  <Grid container alignItems="center" justifyContent="flex-end" xs={2}>
                    <Tooltip title="Filter">
                      <IconButton
                        onClick={() => {
                          setIsOpenAplyFilters(true);
                        }}
                        sx={{ height: '40px' }}
                        disabled={isLoading}
                      >
                        <FilterListIcon htmlColor="#919eab" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : null}
              </Stack>
              <TableContainer>
                <Table>
                  <TableHeadCustom
                    headLabel={headLabel}
                    onSort={onSort}
                    orderBy={orderBy}
                    order={order}
                    headerCustomColumn={PatientHeaderColumn}
                    setOrder={setOrder}
                    hideSortIcon={false}
                  />
                  <TableBody>
                    {!isLoading ? (
                      rows?.length ? (
                        rows.map((row) => <PatientRow key={row.id} row={row} />)
                      ) : (
                        <NoResultsTableRow
                          colSpan={TABLE_HEAD_PATIENT_LIST.length}
                          text={i18n('patients.list.listNotFound', 'crs')}
                        />
                      )
                    ) : (
                      <LoadingTableRow colSpan={TABLE_HEAD_PATIENT_LIST.length} />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid xs={12}>
                <Stack justifyContent="flex-end">
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={totalItems ? totalItems : 0}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={onChangeRowsPerPage}
                    sx={{ borderTop: 0 }}
                  />
                </Stack>
              </Grid>
            </Card>
          </Grid>
          <FilterDrawer
            onApplyButtonClick={() => {
              getPatientList(
                page,
                rowsPerPage,
                search,
                gridState.silFilter,
                [order, orderBy],
                gridState.ownersToFilter
              );
              setIsOpenAplyFilters(false);
            }}
            title={i18n('patients.list.filterPopUp.title', 'crs')}
            onCloseIconButtonClick={() => {
              setIsOpenAplyFilters(false);
            }}
            onClearAllButtonClick={handlerClear}
            anchor={'right'}
            open={isOpenAplyFilters}
          >
            <PatientFiltersList
              gridState={gridState}
              updateGridState={updateGridState}
              setSilFilter={setSilFilters}
              setOwnersToFilter={setOwnersToFilter}
              patientSil={[]}
              users={users}
              i18n={i18n}
            />
          </FilterDrawer>
        </>
      </PatientLayout>
      <AddPatientModal
        open={isAddPatientModalOpen}
        onClose={() => {
          setIsAddPatientModalOpen(false);
        }}
      />
    </>
  );
}

interface PatientFilterListProps {
  gridState: any;
  updateGridState: any;
  patientSil: any;
  setSilFilter: any;
  setOwnersToFilter: any;
  users: any;
  i18n: any;
}

const PatientFiltersList = ({
  gridState,
  updateGridState,
  patientSil,
  setSilFilter,
  setOwnersToFilter,
  users,
  i18n,
}: PatientFilterListProps) => {
  const { componentsData } = useTenantConfigData();
  const { showSILInformation } = componentsData ?? {};
  return (
    <Box py={3}>
      <Box>
        <Grid item sx={{ marginTop: 1 }} xs={12}>
          <Typography fontSize={'1rem'} fontWeight={'bold'} marginBottom={1.4}>
            {i18n('patients.list.filterPopUp.owners', 'crs')}
          </Typography>
          <Autocomplete
            id="combo-box-user"
            value={gridState.usersIds}
            fullWidth
            multiple
            onChange={(_: React.SyntheticEvent, usersFiltered: any) => {
              setOwnersToFilter(usersFiltered);
              updateGridState((prevGridState: any) => ({
                ...prevGridState,
                ownersToFilter: usersFiltered,
              }));
            }}
            options={users ?? []}
            renderInput={(params) => (
              <TextField
                label={i18n('patients.list.filterPopUp.ownersPlaceHolder', 'crs')}
                {...params}
              />
            )}
            renderOption={(props, option, { index }) => (
              <li {...props} key={option?.code + index}>
                {option?.label}
              </li>
            )}
          />
        </Grid>
        <br />
        {!!showSILInformation && (
          <Grid item sx={{ marginTop: 1 }} xs={12}>
            <Typography fontSize={'1rem'} fontWeight={'bold'} marginBottom={1.4}>
              {i18n('patients.list.filterPopUp.sil', 'crs')}
            </Typography>
            <Autocomplete
              value={gridState.silFilter}
              multiple
              fullWidth
              onChange={(_: React.SyntheticEvent, silFilteres) => {
                setSilFilter(silFilteres);
                updateGridState((prevGridState: any) => ({
                  ...prevGridState,
                  silFilter: silFilteres,
                }));
              }}
              options={initialServiceFilter ?? []}
              getOptionLabel={({ display }: ValueSetComposeIncludeConcept) => display ?? ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={i18n('patients.list.filterPopUp.silPlaceHolder', 'crs')}
                  variant="outlined"
                />
              )}
            />
          </Grid>
        )}
      </Box>
    </Box>
  );
};
